import React, { useState } from "react";
import logo10 from "../assets/images/logo-1-1.png";
import logo11 from "../assets/images/logo-2-1.png";
import logo12 from "../assets/images/logo-3-1.png";
import logo13 from "../assets/images/logo-4-1.png";
import logo14 from "../assets/images/logo-5-1.jpg";
import logo15 from "../assets/images/logo-6-1.png";
import logo16 from "../assets/images/logo-7-1.png";
import logo17 from "../assets/images/logo-8-1.png";
import logo18 from "../assets/images/logo-9-1.png";
import { FaPlay } from "react-icons/fa";
import { LogoMain } from "../strings/Array";
import { Link } from "react-router-dom";

const Work = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const [activelogo, setActivelogo] = useState();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  return (
    <div>
      <div className="col-sm-12 flex justify-center items-center w-[100%] ">
        <div className="port-nav-tb cursor-pointer py-6">
          <ul
            id="nav-tabs-wrapper"
            className="nav nav-tabs md:flex justify-center items-center gap-6 flex-wrap "
          >
            <li
              className={`!my-[10px] ${
                activeCategory === "logo" ? "active" : ""
              }`}
            >
              <a
                className="p-3 "
                onClick={() => handleTabClick("logo")}
                id="portfolio_logo"
              >
                Logo Design & Branding
              </a>
            </li>
            <li
              className={`!my-[10px] ${
                activeCategory === "website" ? "active" : ""
              }`}
            >
              <a
                className="p-3"
                onClick={() => handleTabClick("website")}
                id="portfolio_website"
              >
                Website Design
              </a>
            </li>
            <li
              className={`!my-[10px] ${
                activeCategory === "video" ? "active" : ""
              }`}
            >
              <a
                className="p-3 "
                onClick={() => handleTabClick("video")}
                id="portfolio_video"
              >
                Video Animation
              </a>
            </li>
          </ul>
        </div>
      </div>

      {activeCategory === "logo" && (
        <div className="grid md:grid-cols-3 grid-cols-1 justify-center content-center items-center my-3 mt-6 gap-6  w-full h-full">
          {LogoMain.map((logo, index) => (
            <Link to={`/our-portfolio/logo/${logo.route}`} key={logo.route}>
              <div>
                <img
                  src={logo.img}
                  width={1000}
                  height={1000}
                  style={{ objectFit: "cover", margin: "auto" }}
                />
                <div
                  className="content-container"
                  style={{ textAlign: "center" }}
                >
                  <h2 className="meta-large-para">{logo.heading}</h2>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {activeCategory === "website" && (
        <div
          id="portfolio_website"
          className="grid md:grid-cols-3 grid-cols-1 justify-center items-center my-3 mt-6 gap-6  w-[100%] h-[100%]"
        >
          <div className="image-containers w-full">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web1.jpg")})`,
              }}
              className="box box1 "
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web2.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web3.jpg")})`,
                background: "cover",
              }}
              className="box box1 px-3"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web4.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web5.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web6.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web7.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>

          <div className="image-containers">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/website/web8.jpg")})`,
                background: "cover",
              }}
              className="box box1"
            ></div>
          </div>
        </div>
      )}

      {activeCategory === "video" && (
        <div
          id="portfolio_video"
          className="grid md:grid-cols-3 grid-cols-1 justify-center items-center my-3 mt-6 gap-6  w-[100%] h-[90%]"
        >
          <div className="flex ">
            <div className="port-img box12 w-full">
              <a
                href="https://youtu.be/3kziPIpUZy8"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_1.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/gMuLoKl4qyo"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_2.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/qEkVk_I99HI"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_3.jpg")}
                />
                <div className="box-content">
                  <ul className="icon text-white">
                    <li>
                      <i className="fa " aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex ">
            <div className="port-img box12 w-full">
              <a
                href="https://youtu.be/GlybjPXX0Xo"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_4.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa " aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/CnSyth3A-hw"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_5.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/SNTzZh58YeM"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_6.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="ihttps://youtu.be/IabS2eZuVto"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_7.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/aDGZgzVwly4"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_8.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/C-WXTPyI7xQ"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_9.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Work;
