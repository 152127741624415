import "./App.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import About from "./component/About";
import Pricing from "./component/Pricing";
import Contact from "./component/Contact";
import Portfolio from "./component/Portfolio";
import Marketing from "./component/Marketing";
import Website from "./file/Webs/Website";
import LogoDetail from "./file/LogoDetail";
import Designing from "./file/Designing/Designing";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsAndServices from "./component/TermsAndServices";
import Digital_marketing from "./file/marketing/Digital_marketing";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="digital-marketing-service" element={<Marketing />} />
          <Route path="our-portfolio" element={<Portfolio />} />
          <Route path="design" element={<Designing />} />
          <Route path="website" element={<Website />} />
          <Route path="digital-marketing" element={<Digital_marketing />} />
          <Route path="/our-portfolio/logo/:route" element={<LogoDetail />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />{" "}
          {/* Add this line */}
          <Route path="TermsAndServices" element={<TermsAndServices />} />{" "}
          {/* Add this line */}
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<App />);
