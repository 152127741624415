import React from "react";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa6";
import seal from "../assets/images/seal-img.png";
import { useNavigate } from "react-router-dom";
import { webDesignSpec } from "../strings/Array";
const WebDesign = () => {
  const navigate = useNavigate();
  const { webOne, webTwo } = webDesignSpec;
  return (
    <div className="bg-[#F2F2F2] rounded-md mb-10">
      <div className="py-10">
        <div className="md:flex w-[100%]">
          <div className="flex flex-col md:w-[76%]">
            <div className="bg-logo w-[100%] text-white p-6">
              <h1 className="meta-subheading !font-semibold">WEB PACKAGE</h1>
              <h4 className="meta-card-heading !font-medium">
                CUSTOMIZED WEB PORTAL
              </h4>
            </div>
            <div className="lg:flex flex-col pt-[20px]">
              <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="flex flex-col px-10 py-3">
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    {webOne.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 text-logo"
                      >
                        <span className="shrink-0">
                          <TiTick size={25} />
                        </span>
                        <h4 className="meta-paragraph !text-black">{item}</h4>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col px-10 py-3">
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    {webTwo.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 text-logo"
                      >
                        <span className="shrink-0">
                          <TiTick size={25} />
                        </span>
                        <h4 className="meta-paragraph !text-black">{item}</h4>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[20%]">
            <img
              src={seal}
              alt="Seal"
              className="w-auto md:ml-auto md:mx-0 mx-auto"
            />
            <div className="flex flex-col gap-[8px] lg:items-center md:items-start text-center justify-center items-center my-3 ">
              <h1 className="meta-subheading !text-primary">-$4330</h1>
              <p className="meta-paragraph ">Final Price for Limited Time</p>
              <h2 className="meta-subheading !text-logo">$1299</h2>
              <div className="flex justify-center items-center my-3">
                <button
                  className="meta-card-heading !font-semibold !shadow-md rounded-3xl !bg-white !text-primary py-3 px-6 transition-all duration-200 ease-out hover:!bg-primary hover:!text-white hover:!shadow-md"
                  onClick={() => navigate("/contact-us")}
                >
                  Start Project Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDesign;
