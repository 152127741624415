import React from "react";
import { TiTick } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa6";
import seal from "../assets/images/seal-img.png";
import { useNavigate } from "react-router-dom";

const Design = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#F2F2F2] rounded-md mb-10">
      <div className="py-10">
        <div className="md:flex w-[100%]">
          <div className="flex flex-col md:w-[76%]">
            <div className="bg-logo w-[100%] text-white p-6">
              <h1 className="meta-subheading !font-semibold !text-white">
                ULTIMATE <span className="!font-bold">DESIGN</span> PACKAGE
              </h1>
              <h4 className="meta-card-heading !font-medium !text-white">
                SPECIAL ONLINE LOGO DESIGN OFFER OF THE MONTH!
              </h4>
            </div>
            <div className="lg:flex flex-col pt-[20px]">
              <div className="flex md:flex-row flex-col ">
                <div className="flex flex-col px-10 py-3">
                  <div className="flex items-center text-logo gap-1">
                    <FaArrowRight size={20} />
                    <h1 className="meta-card-heading !text-black">
                      Logo Design
                    </h1>
                  </div>
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Unlimited Logo Design Concepts
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        4 Dedicated Logo Designers
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Unlimited Revisions
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-10 py-3">
                  <div className="flex items-center text-logo gap-1">
                    <FaArrowRight size={20} />
                    <h1 className="meta-card-heading !text-black">
                      Landing Page
                    </h1>
                  </div>
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        1 Customized Landing Page
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">Design</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col">
                <div className="flex flex-col px-10 py-3">
                  <div className="flex items-center text-logo gap-1">
                    <FaArrowRight size={20} />
                    <h1 className="meta-card-heading !text-black">
                      Website Design
                    </h1>
                  </div>
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        {" "}
                        10 Unique Page Custom Website
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        {" "}
                        Unlimited Revisions
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Content Management System
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Complete W3C Certified HTML
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Responsive Design
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        01 Year Web Hosting
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        10 Stock Photos
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Email Addresses
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Google Friendly Sitemap
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-10 pt-3">
                  <div className="flex items-center text-logo gap-1">
                    <FaArrowRight size={20} />
                    <h1 className="meta-card-heading !text-black">
                      {" "}
                      Social Media Pages
                    </h1>
                  </div>
                  <div className="flex flex-col mx-3 my-3 gap-[5px]">
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Facebook, Youtube & Twitter
                      </h1>
                    </div>
                    <div className="flex items-center gap-1 text-logo">
                      <TiTick size={25} />
                      <h1 className="meta-paragraph !text-black">
                        Pages Design
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[20%]">
            <img
              src={seal}
              alt="Seal"
              className="w-auto md:ml-auto md:mx-0 mx-auto"
            />
            <div className="flex flex-col gap-[8px] lg:items-center md:items-start text-center justify-center items-center my-3 ">
              <h1 className="meta-subheading !text-primary">-$4330</h1>
              <p className="meta-paragraph ">Final Price for Limited Time</p>
              <h2 className="meta-subheading !text-logo">$1299</h2>
              <div className="flex justify-center items-center my-3">
                <button
                  className="meta-card-heading !font-semibold !shadow-md rounded-3xl !bg-white !text-primary py-3 px-6 transition-all duration-200 ease-out hover:!bg-primary hover:!text-white hover:!shadow-md"
                  onClick={() => navigate("/contact-us")}
                >
                  Start Project Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
