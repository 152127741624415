import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/main-logo.png";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import ScreenContainer from "./ScreenContainer";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import {  FaHome } from "react-icons/fa";

const navData = [
  { id: 1, name: "home", link: "/" },
  {
    id: 2,
    name: "our process",
    icon: <FaAngleDown className="mt-1 hover:text-logo" />,
  },
  { id: 3, name: "portfolio", link: "/our-portfolio" },
  { id: 4, name: "pricing", link: "/pricing" },
  { id: 5, name: "about", link: "/about-us" },
  { id: 3, name: "contact", link: "/contact-us" },
];
const mobnavData = [
  {
    id: 1,
    name: "home",
    link: "/",
  },
  {
    id: 2,
    name: "logo & branding",
    link: "/design",
  },
  {
    id: 3,
    name: "web design",
    link: "/website",
  },
  {
    id: 4,
    name: "video animation",
    link: "/branding",
  },
  {
    id: 5,
    name: "digital marketing",
    link: "/digital-marketing",
  },

  {
    id: 6,
    name: "portfolio",
    link: "/our-portfolio",
  },
  {
    id: 7,
    name: "pricing",
    link: "/pricing",
  },
  {
    id: 8,
    name: "about",
    link: "/about-us",
  },
  {
    id: 9,
    name: "contact",
    link: "/contact-us",
  },
];
function Header({ backgroundColor }: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [current, setCurrent] = useState<any>();
  const navigate = useNavigate();

  return (
    <nav className="shadow-[0_4px_8px_0_rgba(0,0,0,0.1)]">
      <ScreenContainer>
        <div className="w-full flex items-center justify-between py-[20px]  ">
          <div>
            <Link to={"/"}>
              <img src={logo} alt="Logo" className="w-auto h-[30px]" />
            </Link>
          </div>
          {/* desktop */}
          <div className="hidden  xl:flex flex-1  justify-end gap-[26px] ">
            {navData?.map((value: any, index: any) => {
              return (
                <Link to={value?.link} key={index}>
                  <div
                    className="flex gap-[4px] items-center "
                    onMouseEnter={() => {
                      setCurrent(value?.id);
                    }}
                  >
                   {value?.name==="home" ? <>
                      <span className="menu-hover-effect">
                   <FaHome/>
                  </span>
                      </>:
                      <>
                    <span className="menu-hover-effect meta-nav-link">
                      {value?.name}
                    </span>
                    {value?.icon && (
                      <div className="mb-[4px]">{value?.icon}</div>
                    )}
                    </>}
                  </div>
                </Link>
              );
            })}
          </div>
          {/* tablet & mob */}
          <div className="xl:hidden flex flex-1  justify-end">
            {menuOpen ? (
              <div
                onClick={() => setMenuOpen(!menuOpen)}
                className={`cursor-pointer ${
                  scrolling
                    ? "bg-white text-black font-semibold"
                    : "text-black bg-white"
                }`}
              >
                <IoCloseSharp size={18} />
              </div>
            ) : (
              <div
                onClick={() => setMenuOpen(!menuOpen)}
                className={`cursor-pointer ${
                  scrolling
                    ? "bg-white text-black font-semibold"
                    : "text-black bg-white"
                }`}
              >
                <AiOutlineMenu size={18} />
              </div>
            )}
            <div
              className={`fixed top-0 z-[9999] h-[100vh]  w-full  transform overflow-hidden overflow-y-auto   bg-white transition-all duration-500 [-ms-overflow-style:none]  [scrollbar-width:none] [&::-webkit-scrollbar]:hidden ${
                menuOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              <div className="p-[30px] ">
                <div className="flex justify-between">
                  <Link to={"/"}>
                    <img src={logo} alt="Logo" className="w-auto h-[30px]" />
                  </Link>
                  <div
                    onClick={() => setMenuOpen(!menuOpen)}
                    className={`cursor-pointer ${
                      scrolling
                        ? "bg-white text-black font-semibold"
                        : "text-black bg-white"
                    }`}
                  >
                    <IoCloseSharp size={18} />
                  </div>
                </div>
                <hr className="my-[20px]" />

                {mobnavData?.map((item, index) => {
                  return (
                    <Link to={item?.link}>
                      <div
                        key={index}
                        className="meta-paragraph uppercase text-primary py-[10px] menu-hover-effect flex justify-between"
                      >
                        <p>{item?.name}</p>
                        <FaAngleRight className="mt-1 hover:text-logo text-primary" />
                      </div>
                      <hr />
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </ScreenContainer>
      {current === 2 && (
        <div
          className="w-full bg-white "
          onMouseLeave={() => {
            setCurrent(null);
          }}
        >
          <hr />
          <ScreenContainer>
            <div className="flex gap-[20px] py-[30px]">
              <div className="w-[25%]">
                <p className="meta-nav-heading">METANADO</p>
                <p className="meta-nav-paragraph">
                  Make your brand speak crystal clear and loud with our ROI
                  focused design, development and marketing services.
                </p>
                <div className="drop-down-ul">
                  <ul>
                    <li className="flex items-center">
                      <a href="/pricing">
                        <FaAngleRight size={18} className="mt-[4px] pr-[4px]" />
                        <span className="meta-nav-paragraph">
                          Check Pricing
                        </span>
                      </a>
                    </li>
                    <li className="flex items-center">
                      <a href="/our-portfolio">
                        <FaAngleRight size={18} className="mt-[4px] pr-[4px]" />
                        <span className="meta-nav-paragraph">
                          View Portfolio
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {[
                {
                  id: 1,
                  name: "Logo Design & Branding",
                  imgPath: "https://www.prismedium.com/assets/home/images/menu/1.jpg",
                  subheading:
                    "Make a Strong First Impression to Leave a Lasting Impact!",
                  para: "At METANADO, we're experts in crafting eye-catching visual identities that make a statement...",
                  link: "/design",
                },
                {
                  id: 2,
                  name: "Website Development",
                  imgPath: "https://www.prismedium.com/assets/home/images/menu/2.jpg",
                  subheading:
                    "Conquer the online fraternity with our web development services",
                  para: "Our web development services include robust and beautifully carved websites which beat the competition with ease.",
                  link: "/website",
                },
                {
                  id: 3,
                  name: "Digital Marketing",
                  imgPath: "https://www.prismedium.com/assets/home/images/menu/3.jpg",
                  subheading: "Paid Marketing",
                  para: "Boost your growth with METANADO's specialized paid marketing plans. We design captivating ad campaigns on various channels to boost traffic and increase conversions quickly.",
                  link: "/digital-marketing",
                },
              ]?.map((val, index) => {
                return (
                  <div
                    className="w-full max-w-[25%] flex flex-col gap-[12px] "
                    key={index}
                  >
                    <div className="group">
                      <p className="meta-nav-heading group-hover:text-primary">
                        {val.name}
                      </p>
                      <div className="drop-hover flex-1 cursor-pointer">
                        <div className="drop-img">
                          <a onClick={() => navigate(val?.link)}>
                            <img
                              src={val?.imgPath}
                              loading="lazy"
                              alt="Logo And Branding Design"
                              className="object-contain"
                            />
                            <div className="drop-img-hover">
                              <p>Discover</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <h2 className="meta-nav-paragraph !font-bold	h-[40px]">
                      {val.subheading}
                    </h2>
                    <p className="meta-nav-paragraph line-clamp-3">
                      {val.para}
                    </p>
                    <a
                      onClick={() => navigate(val?.link)}
                      className="drop-view-link cursor-pointer	"
                    >
                      {" "}
                      <i className="fa">
                        <FaAngleRight />
                      </i>{" "}
                      View More{" "}
                    </a>
                  </div>
                );
              })}
            </div>
          </ScreenContainer>
        </div>
      )}
    </nav>
  );
}

export default Header;
