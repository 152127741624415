import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { webfaqs } from "../../strings/Array";
import { WebsiteData } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import WebsiteCard from "../../component/WebsiteCard";
import { testimonialWeb } from "../../strings/Array";
import company1 from "../../assets/company2/1582115760-logodatum.svg";
import company2 from "../../assets/company2/1582115762-logodescript.svg";
import company3 from "../../assets/company2/1582115763-logoelph-landscape.svg";
import company4 from "../../assets/company2/1582115766-logofilecoin.svg";
import company5 from "../../assets/company2/1582115767-logofinal.svg";
import company6 from "../../assets/company2/1582115768-logoflyr.svg";
import company7 from "../../assets/company2/1582115772-logohackerrank.svg";
import company8 from "../../assets/company2/1582115774-logohana-dark.svg";
import company9 from "../../assets/company2/1582115784-logoiterable.svg";
import company10 from "../../assets/company2/1582115795-logomobingi-dark.svg";
import company11 from "../../assets/company2/1582115797-logomozilla.svg";
import company12 from "../../assets/company2/1582115806-logoprotocol-labs.svg";
import company13 from "../../assets/company2/1582115824-logotruebill.svg";
import company14 from "../../assets/company2/1582115829-logowizeline-dark.svg";
import company15 from "../../assets/company2/1582115834-logoxero.svg";
import logo from "../../assets/images/main-logo.png";
import logo3d from "../../assets/images/logo-inner-img.gif";
import logo3d2 from "../../assets/images/logo-inner-img3_1.gif";
import "./Website.css";
import TitleWithSubTitleDesc from "../../component/TitleWithSubTitleDesc";
import TestimonialSlider from "../../component/sliders/TestimonialSlider";
import arrow from "../../assets/images/arrow_1.svg";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import menu from "../../strings/Array";
import { testimonial } from "../../strings/Array";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Project from "../../component/WebProject";
import ScreenContainer from "../../component/ScreenContainer";
import WorkHome from "../../WorkDev";
import {
  testimonialsHomeTitleParaA,
  testimonialsHomeTitleParaB,
  aboutServiceParaA,
} from "../../strings/Array";
function Website() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null
  const navigate = useNavigate();

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className="w-full ">
      {/* <div className="fixed top-0 z-10"> */}
      <Header />
      {/* </div> */}

      <div className="bg-primary py-[40px] sm:py-[50px] lg:py-[80px] !pb-[10px]">
        <ScreenContainer>
          <div className="flex gap-[20px]">
            <div className="w-auto flex flex-col gap-[20px]">
              <div>
                <h5 className="meta-large-para !text-textWhite">
                  Recognized Your Brand Online
                </h5>
                <h1 className="meta-subheading !text-textWhite">
                  <span>Rule The </span> with A
                  <br /> Your
                  <span className="bnr-spn-clr"> Modern Website</span>{" "}
                </h1>
              </div>
              <h4 className="meta-large-para !text-textWhite">
                A website is an excellent method to expand your customer base,
                and make a positive first impression on prospective customers
              </h4>
              <ul className="meta-large-para !text-textWhite">
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Service-based websites
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  E-commerce websites
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  SEO-optimized
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Latest technology
                </li>
              </ul>
              <div className="bnr-btn">
                <a
                  // onClick={scrollToPriceTb1}
                  className="get-strtd popup-btn cursor-pointer"
                >
                  Get Started
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </a>
                <a
                  onClick={() => navigate("/contact-us")}
                  className="live-cht chats !text-textWhite"
                >
                  Live Chat{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <h6 className="meta-large-para !text-textWhite">
                Implementation process is easy and completly free. Contact us
                for further info
              </h6>
            </div>
            <div className="w-auto ">
              <div className=" hidden lg:block !bg-transparent">
                <img
                  className=" transition-transform duration-300 ease-in-out hover:scale-110 w-full"
                  src={"https://www.prismedium.com/images/web-bnr-1-img.png"}
                />
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <ScreenContainer>
        <div className=" w-auto mx-auto flex justify-center items-center "></div>
        <div className="flex flex-col  w-auto mx-auto py-[40px] sm:py-[50px] lg:py-[80px]">
          {/* {menu.map((menu, id) => {
              return ( */}
          <div
            className="fadeIn"
            style={{
              display: true ? "block" : "none",
            }}
          >
            <div className="md:flex  justify-between gap-[20px]">
              <div className="md:w-[50%] w-auto flex flex-col  ">
                {/* <div className="flex gap-3">
                      <img src={arrow} alt="Arrow" className="w-[10%]" />
                      <h1 className="meta-heading-label">{menu.title}</h1>
                    </div> */}
                <h1 className="meta-subheading">{menu[1].heading}</h1>
                <h2 className="meta-heading-label !text-secondary">
                  {menu[1].desc}
                </h2>
                <p className="meta-paragraph mt-[10px]">{menu[1].para}</p>
                <p className="meta-paragraph mt-[10px]">{menu[1].para2}</p>
              </div>
              <div className="md:w-[50%] w-auto flex justify-center items-center mx-auto mt-[30px]  md:mt-0">
                <img src={menu[1].image} alt="Image" width={"100%"} />
              </div>
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </ScreenContainer>

      <ScreenContainer>
        <TitleWithSubTitleDesc
          bgColor="white"
          data={{
            // subTitle: " OUR AWESOME WORK",
            mainTitle: " A snippet of our portfolio from our database.",
            description:
              "CanDigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.",
          }}
        />

        <WorkHome />
      </ScreenContainer>
      {!isMobile && <Project />}

      {/* <div className="py-[40px] xs:py-[40px] sm:py-[50px] lg:py-[80px]">
        <ScreenContainer>
          <motion.div
            variants={fadeInAnimationVarinats}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            className="text-white flex  justify-center flex-col  md:justify-start "
          >
            <h2 className="meta-subheading text-textBlack mb-[20px]">
              Web design
            </h2>
            <p className="meta-large-para text-textBlack">
              METANADO's team of web designers specialize in creating visually
              appealing websites and landing pages that not only tell your
              brand's story, but also help increase conversion rates and
              establish credibility with your target audience.
            </p>
          </motion.div>

          <motion.div
            variants={fadeInAnimationVarinats}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className="flex flex-wrap justify-start pt-16 "
          >
            {WebsiteData.map((card: any, index) => (
              <div
                key={index}
                className="xs:w-full sm:w-1/2 w-full flex justify-center md:w-1/2  lg:w-1/3 "
              >
                <DesignCard {...card} />
              </div>
            ))}
          </motion.div>
        </ScreenContainer>
      </div> */}

      {/* sepate compon */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className=" bg-[#141414] text-[#141414] justify-center flex-col  md:justify-start py-[40px] sm:py-[50px] lg:py-[80px]"
      >
        <ScreenContainer>
          <div className="flex flex-row justify-between lg:justify-around w-full">
            <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
              <h2 className="meta-subheading !text-textWhite mb-[20px]">
                Marketing website design approach
              </h2>
              <p className="meta-large-para !text-textWhite">
                We believe that marketing websites play a crucial role in
                allowing brands to share their individual narratives and
                showcase their distinctive qualities. Our strategy guarantees
                that these websites convey a consistent tone that resonates with
                the brand's fundamental characteristics, successfully conveying
                the intended message to the desired audience. When crafting
                these websites, we integrate key components of brand identity to
                establish a unified experience that mirrors both the brand's
                present status and its future ambitions and strategic
                objectives.
              </p>
            </div>

            <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
              <img src={logo3d} alt="Logo" className="w-auto rounded-lg" />
            </div>
          </div>
        </ScreenContainer>
        <ScreenContainer>
          <div className="flex flex-col justify-start items-start gap-[10px] mt-[40px] sm:mt-[50px] lg:mt-[80px] mb-[20px]">
            <h2 className="meta-subheading !text-textWhite">
              CUSTOMIZED WEB PORTAL PACKAGE
            </h2>
            <p className="meta-large-para  w-auto  !text-textWhite">
              Our web portal development approach creates a seamless digital
              platform that reflects your brand's strategy, visual identity, and
              voice. This customized solution doesn't just boost your market
              presence, but also improves user interaction and operational
              effectiveness.
            </p>
          </div>
        </ScreenContainer>
        <ScreenContainer>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px]">
            <div className="flex flex-col justify-start items-start gap-[10px] ">
              <h4 className="meta-nav-heading !font-bold !text-start !text-textWhite">
                User experience
              </h4>
              <ul className="list-disc pl-[15px] meta-large-para !text-textWhite">
                <li>Discovery</li>
                <li>UI/UX audit</li>
                <li>Information architecture</li>
                <li>Copy and CTAs</li>
                <li>nclusive design</li>
              </ul>
            </div>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h4 className="meta-nav-heading !font-bold !text-start !text-textWhite">
                Design & front-end
              </h4>
              <ul className="list-disc pl-[15px] meta-large-para !text-textWhite">
                <li>Visual design & interactions</li>
                <li>TFront-end development</li>
                <li>CMS</li>
                <li>Iconography & illustrations</li>
                <li>3rd party integrations</li>
              </ul>
            </div>

            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h4 className="meta-nav-heading !font-bold !text-start !text-textWhite">
                Technology stack
              </h4>
              <ul className="list-disc pl-[15px] meta-large-para !text-textWhite">
                <li>Astro</li>
                <li>NEXT.js</li>
                <li>Gatsby</li>
                <li>Headless CMS</li>
                <li>Webflow</li>
              </ul>
            </div>
          </div>
        </ScreenContainer>
      </motion.section>
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-0 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h2 className="meta-subheading">Additional services</h2>
              <p className="meta-large-para  w-auto ">
                we offer a wide range of specialized services aimed at providing
                outstanding value, promoting growth, and enhancing user
                experiences in order to build a strong digital presence.
              </p>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px] ">
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Branding
                </h4>
                <p className="l meta-large-para">
                  Collaborate with our experts to create a unique brand identity
                  that builds trust and enhances visibility.
                </p>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Website Design & Development
                </h4>
                <p className="l meta-large-para">
                  Create websites that prioritize the user experience, build
                  brand trust, and deliver meaningful outcomes.{" "}
                </p>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Logo Design
                </h4>
                <p className="l meta-large-para">
                  Design logos that connect with your audience and embody the
                  heart of your brand's narrative and principles.{" "}
                </p>
              </div>

              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Marketing Strategies
                </h4>
                <p className="l meta-large-para">
                  Create customized marketing strategies to boost brand
                  awareness, engage with specific customer segments, and drive
                  company expansion.
                </p>
              </div>

              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Consulting Services
                </h4>
                <p className="l meta-large-para">
                  Get expert advice and tailored recommendations to enhance your
                  online strategies and reach sustainable growth.
                </p>
              </div>

              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Creative Campaigns
                </h4>
                <p className="l meta-large-para">
                  Start Exciting Campaigns that enthrall your audience and
                  efficiently get your brand's message across different
                  channels.
                </p>
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      {/* component */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-2 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <h2 className="meta-subheading"> Website design clients</h2>
                <p className="meta-large-para  w-auto ">
                  Recognized brands and emerging startups with the backing of
                  top VC firms across the globe.
                </p>
              </div>
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo3d2} alt="Logo" className="w-auto rounded-lg " />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        className="  w-full  bg-gray-100 "
      >
        <ScreenContainer>
          <div className="flex flex-wrap justify-start">
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company1}
                alt="Company Logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
          </div>
        </ScreenContainer>
      </motion.section>

      <div
        className="white-sec pb-8 flex flex-col gap-[40px] "
        id="why-choose-us-linker"
      >
        <div>
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <div className="flex justify-start items-start">
                  <img src={arrow} alt="Arrow" className="w-[5%]" />
                  <h4
                    className="meta-paragraph"
                    style={{ textTransform: "uppercase" }}
                  >
                    {"choose us"}
                  </h4>
                  <img
                    src={arrow}
                    alt="Arrow"
                    className="w-[5%] transform rotate-180"
                  />
                </div>
                <h2
                  className="meta-subheading !text-start "
                  style={{ textTransform: "capitalize" }}
                >
                  Why METANADO?
                </h2>
                <p
                  className="meta-large-para !text-start w-full"
                  style={{ fontWeight: "400" }}
                >
                  METANADO is the top pick for branding design because we have a
                  unique skill set in creating impactful and unforgettable brand
                  identities, especially for innovative companies. We are
                  experts in providing comprehensive branding solutions, from
                  design to strategy, making sure your brand shines in a
                  competitive market
                </p>
              </div>
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo} alt="Logo" className="w-auto h-[60px]" />
              </div>
            </div>
          </ScreenContainer>
        </div>
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
      </div>
      {/* sections */}

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div className="bg-[#F1F1F1] my-10">
          <div className="flex flex-col justify-center items-center py-10 w-[100%]">
            <TitleWithSubTitleDesc data={testimonialsHomeTitleParaA} />
            <ScreenContainer>
              <div className="slier-section-testimonials my-10">
                <TestimonialSlider data={testimonial} />
              </div>
            </ScreenContainer>

            {/* <TitleWithSubTitleDesc data={testimonialsHomeTitleParaB} /> */}
          </div>
        </div>
      </motion.section>

      {/* FAQ */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="flex  bg-white text-[#141414]"
      >
        <Accordion data={webfaqs} />
      </motion.section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Website;
