import { IoIosStar } from "react-icons/io";
import testi from "../../src/assets/images/testi-quote.svg";

const TestimonialCard = ({data}: any) => {
  const { para, image, name, post } = data;
   console.log("data", data);
  return (
    <div className="bg-white transform hover:translate-y-[-4px] ease-out duration-300 hover:bg-logo hover:text-white p-6 w-full h-screen flex flex-col justify-start items-start gap-6 min-h-min-[250px] max-h-[270px]">
      <h1>{para}</h1>
      <div className="px-3 flex justify-center items-center gap-3">
        <img src={image} alt="Image" className="rounded-full w-12 h-12" />
        <div className="flex flex-col">
          <div className="flex text-yellow-500">
            {[...Array(5)].map((_, index) => (
              <IoIosStar key={index} fill="#1d224a" size={25} />
            ))}
          </div>
          <h1 className="font-bold">{name}</h1>
          <h2>{post}</h2>
        </div>
        <img src={testi} alt="Testi" className="w-8 h-8" />
      </div>
    </div>
  );
};

TestimonialCard.defaultProps = {
  testimonial: {
    para: "",
    image: "",
    name: "",
    post: "",
  },
};

export default TestimonialCard;
