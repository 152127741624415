import React from "react";
import emailjs from "emailjs-com";
import { CONTACT } from "../strings/CONTACT";
import ScreenContainer from "../component/ScreenContainer";

const ContactForm = () => {
  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Get form data (you may need to add state variables for these inputs)
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;
    // const clas = e.target.elements.clas.value;
    // const message = e.target.elements.message.value;

    // Use emailjs to send the email
    emailjs
      .send(
        "service_xxc33sv", // Replace with your EmailJS service ID
        "template_doju94d", // Replace with your EmailJS template ID
        {
          from_name: name,
          message: `Name: ${name}, Email: ${email}, Phone: ${phone} , Message: ${message}`,
          reply_to: email,
        },
        "qx2NQMbJ5_OOwABuV" // Replace with your EmailJS user ID
      )
      .then(() => {
        // Handle success
        console.log("Email sent successfully!");
        // You can add any other actions you want to perform on success
      })
      .catch((error) => {
        // Handle error
        console.error("Email failed to send. Error:", error);
        // You can add any other actions you want to perform on error
      });
  };

  return (
    <div>
      <div className="special-form">
        <ScreenContainer>
          <div className="row md:flex gap-[30px]">
            <div className="md:w-[55%] w-auto">
              <div className="sp-form-txt">
                <h4 className="meta-subheading">Sign-up With Us To Avail</h4>
                <h2 className="meta-heading">Special Discount</h2>
                <p className="meta-paragraph">
                  At CanDigitals, we give you surprising limitless discounts on
                  our services. We want to give esteem based yield. Our
                  administrations depend on a client driven approach, and
                  subsequently, we know how to esteem these connections.{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="ftr-form-st">
                <form onSubmit={handleSubmit} className="formContainer">
                  <div className="row">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                      <div className="col-md-6">
                        <div className="feildDv">
                          <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="feildDv">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="feildDv">
                          <input
                            type="tel"
                            className="phonefield2"
                            placeholder="Phone"
                            name="phone"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="feildDv">
                          <textarea
                            placeholder="Message"
                            name="message"
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 sp-form-btn md:flex">
                      <div className="flex">
                        <div className="feildDv form-btn-div-st2">
                          <button
                            type="submit"
                            className="btn btn-primary requestDv anim-btn2"
                          >
                            Submit
                          </button>
                        </div>
                        <div className="form-btn-div-cht flex justify-center items-center cursor-pointer">
                          <a className="chats">Live Chat</a>
                        </div>
                      </div>
                      <div className="form-btn-div-tel md:pt-0 pt-3">
                        <a href={`tel:${CONTACT.PH_NO}`}                   className="hover:text-logo meta-paragraph hover:text-primary"
                        >
                          Call Us: {CONTACT.PH_NO}
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>
    </div>
  );
};

export default ContactForm;
