import React, { useEffect, useState } from "react";
import { logosData } from "../strings/Array";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ScreenContainer from "../component/ScreenContainer";
const Project = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleHover = (index: any) => {
    setActiveIndex(index);
  };
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-[24px] pt-[40px] sm:pt-[50px] lg:pt-[80px]">
      <ScreenContainer>
        <div className="flex flex-col gap-[10px]">
          <h2 className="meta-subheading">
            Elevate Your Brand’s Online Presence with A Website
          </h2>
          <h4 className="meta-card-heading !font-medium">
            We help your business enter the digital world through innovative web
            solutions
          </h4>
          <p className="meta-paragraph" data-wow-delay="350ms">
            Our goal is to make your business prosper by bringing it into the
            spotlight in the digital world. We have an in-house experienced team
            of web experts who create unique websites as per your business’s
            requirements. Using leading-edge technologies, our experts create
            websites that provide users with a unique experience and compels
            them to work with your brand.
          </p>
        </div>
      </ScreenContainer>
      <ScreenContainer>
        <div className="flex flex-col justify-center items-center">
          <div className="uniq-lg-ul">
            <ul className="overflow-x-hidden w-[100%]">
              {logosData.map((logo, index) => (
                <li
                  key={index}
                  onMouseEnter={() => handleHover(index)}
                  className={`uniq-logo-div-li uniq-logo-div-li-${index + 1} ${
                    activeIndex === index ? "active" : ""
                  }`}
                >
                  <div className="uniq-lg-details">
                    <h4 className="meta-card-heading">{logo.title}</h4>
                    <div className="logo-inner-u-txt">
                      <h3 className="meta-subheading">{logo.title}</h3>
                      <p className="meta-paragraph">{logo.description}</p>
                      <ul>
                        {logo.images.map((image, imgIndex) => (
                          <li key={imgIndex}>
                            <img src={image} alt={`Logo ${imgIndex + 1}`} />
                          </li>
                        ))}
                      </ul>
                      <div className="bnr-btn">
                        <a
                          onClick={() => navigate("/contact-us")}
                          className="get-strtd popup-btn"
                        >
                          Get Started{" "}
                          <i className="fa fa-power-off" aria-hidden="true"></i>
                        </a>
                        <a
                          onClick={() => navigate("/contact-us")}
                          className="live-cht chats"
                        >
                          Live Chat{" "}
                          <i className="fa fa-chevron-right" aria-hidden="true">
                            <FaChevronRight />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ScreenContainer>
      <ScreenContainer>
        <div className="flex flex-col justify-center">
          <div className="link-area mx-auto">
            <a
              className="hover-effect"
              onClick={() => navigate("our-portfolio")}
            >
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>{" "}
          </div>
        </div>
      </ScreenContainer>
    </div>
  );
};

export default Project;
