import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { designfaqs, logoData, logosData } from "../../strings/Array";
import { DesignData } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import WebsiteCard from "../../component/WebsiteCard";
import company1 from "../../assets/company/1582115748-logoappannie.svg";
import company2 from "../../assets/company/1582115751-logoavast.svg";
import company3 from "../../assets/company/1582115753-logocafex.svg";
import company4 from "../../assets/company/1582115760-logodatum.svg";
import company5 from "../../assets/company/1582115767-logofinal.svg";
import company6 from "../../assets/company/1582115770-logoforward.svg";
import company7 from "../../assets/company/1582115780-logohumanapi.svg";
import company8 from "../../assets/company/1582115786-logojune.svg";
import company9 from "../../assets/company/1582115797-logomozilla.svg";
import company10 from "../../assets/company/1582115802-logooppo.svg";
import company11 from "../../assets/company/1582115804-logooracle.svg";
import company12 from "../../assets/company/1582115813-logosalesforce.svg";
import company13 from "../../assets/company/1582115816-logosouthern-company.svg";
import company14 from "../../assets/company/1582115821-logotile.svg";
import company15 from "../../assets/company/1582115824-logotruebill.svg";
import company16 from "../../assets/company/1582115825-logoturo.svg";
import TitleWithSubTitleDesc from "../../component/TitleWithSubTitleDesc";
import TestimonialSlider from "../../component/sliders/TestimonialSlider";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import Project from "../../file/Project";
// import logo from "../assets/images/main-logo.png";
import logo from "../../assets/images/main-logo.png";
import logo3d from "../../assets/images/logo-inner-img.gif";
import logo3d2 from "../../assets/images/logo-inner-img3_1.gif";

import { useMediaQuery } from "react-responsive";

import WorkHome from "../../LogoWork";
import menu from "../../strings/Array";

import arrow from "../../assets/images/arrow_1.svg";

import { useNavigate } from "react-router-dom";
import { testimonial } from "../../strings/Array";

import { testimonialsHomeTitleParaA } from "../../strings/Array";
import "./Designing.css";
import ScreenContainer from "../../component/ScreenContainer";

function Designing() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  return (
    // bg-[#141414]
    <div className="w-full ">
      {/* <div className="fixed top-0 z-10"> */}
      <Header />
      {/* </div> */}
      <div className="bg-primary py-[40px] sm:py-[50px] lg:py-[80px] !pb-[10px]">
        <ScreenContainer>
          <div className="flex gap-[20px]">
            <div className="w-auto flex flex-col gap-[20px]">
              <div>
                <h5 className="meta-large-para !text-textWhite">
                  Get Recognized in a Blink of an Eye
                </h5>
                <h1 className="meta-subheading !text-textWhite">
                  <span>Logos </span> That Boost <br /> Your
                  <span className="bnr-spn-clr"> Brand’s Credibility</span>{" "}
                </h1>
              </div>
              <h4 className="meta-large-para !text-textWhite">
                Let us empower your brand with a proven record of logo designing
              </h4>
              <ul className="meta-large-para !text-textWhite">
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Unique Designs
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Experienced team
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  100% ownership
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Unlimited Revisions
                </li>
              </ul>
              <div className="bnr-btn">
                <a
                  // onClick={scrollToPriceTb1}
                  className="get-strtd popup-btn cursor-pointer"
                >
                  Get Started
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </a>
                <a
                  onClick={() => navigate("/contact-us")}
                  className="live-cht chats !text-textWhite"
                >
                  Live Chat{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <h6 className="meta-large-para !text-textWhite">
                Implementation process is easy and completly free. Contact us
                for further info
              </h6>
            </div>
            <div className="w-auto ">
              <div className=" hidden lg:block !bg-transparent">
                <img
                  className=" transition-transform duration-300 ease-in-out hover:scale-110 w-full pb-[20px] max-w-[400px] w-full"
                  src={"https://www.prismedium.com/images/logo-bnr-2-img.png"}
                />
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <ScreenContainer>
        <div className=" w-auto mx-auto flex justify-center items-center "></div>
        <div className="flex flex-col  w-auto mx-auto py-[40px] sm:py-[50px] lg:py-[80px]">
          {/* {menu.map((menu, id) => {
              return ( */}
          <div
            className="fadeIn"
            style={{
              display: true ? "block" : "none",
            }}
          >
            <div className="md:flex  justify-between gap-[20px]">
              <div className="md:w-[50%] w-auto flex flex-col  ">
                {/* <div className="flex gap-3">
                      <img src={arrow} alt="Arrow" className="w-[10%]" />
                      <h1 className="meta-heading-label">{menu.title}</h1>
                    </div> */}
                <h1 className="meta-subheading">{menu[0].heading}</h1>
                <h2 className="meta-heading-label !text-secondary">
                  {menu[0].desc}
                </h2>
                <p className="meta-paragraph mt-[10px]">{menu[0].para}</p>
                <p className="meta-paragraph mt-[10px]">{menu[0].para2}</p>
              </div>
              <div className="md:w-[50%] w-auto flex justify-center items-center mx-auto mt-[30px]  md:mt-0">
                <img src={menu[0].image} alt="Image" width={"100%"} />
              </div>
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </ScreenContainer>

      <ScreenContainer>
        <TitleWithSubTitleDesc
          bgColor="white"
          data={{
            // subTitle: " OUR AWESOME WORK",
            mainTitle: " A snippet of our portfolio from our database.",
            description:
              "CanDigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.",
          }}
        />

        <WorkHome />
      </ScreenContainer>
      {!isMobile && <Project />}
      <div className="pb-[40px] sm:pb-[50px] lg:pb-[80px]"></div>

      {/* <div className="py-[40px] xs:py-[40px] sm:py-[50px] lg:py-[80px]">
        <ScreenContainer>
          <motion.div
            variants={fadeInAnimationVarinats}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            className="text-white flex  justify-center flex-col  md:justify-start "
          >
            <h2 className="meta-subheading text-textBlack mb-[20px]">
              Logo Design
            </h2>
            <p className="meta-large-para text-textBlack">
              Your brand's logo is like the face of your business. It's what
              catches people's attention and sticks in their minds. Our skilled
              designers create logos that connect with your target market and
              tell the story of your brand in a special way.
            </p>
          </motion.div>

          <motion.div
            variants={fadeInAnimationVarinats}
            initial="initial"
            whileInView="animate"
            viewport={{
              once: true,
            }}
            className="flex flex-wrap justify-start pt-16 "
          >
            {DesignData.map((card: any, index: any) => (
              <div
                key={index}
                className="xs:w-full sm:w-1/2 w-full flex justify-center md:w-1/2  lg:w-1/3 "
              >
                <DesignCard {...card} />
              </div>
            ))}
          </motion.div>
        </ScreenContainer>
      </div> */}
      {/* sepate compon */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className=" bg-[#141414] text-[#141414] justify-center flex-col  md:justify-start py-[40px] sm:py-[50px] lg:py-[80px]"
      >
        <ScreenContainer>
          <h2 className="meta-subheading !text-textWhite mb-[20px]">
            Different Logo Design Options at METANADO{" "}
          </h2>

          <p className="meta-large-para !text-textWhite">
            Wordmarks: METANADO specializes in creating wordmarks that visually
            transform your brand name, making it instantly recognizable and
            memorable through striking typographic design. Lettermarks: we
            specialize in creating polished and professional logos by
            incorporating your business's initials, which are ideal for
            cultivating a refined and elegant brand identity. Brandmarks: The
            brandmarks of METANADO use distinctive symbols or icons to embody
            your business, establishing a powerful visual connection without
            depending on text. Combination Marks: METANADO's combination marks
            combine text and symbols to craft versatile logo that clearly
            conveys your brand's identity. Emblems: At METANADO, our emblems
            combine text with symbols or icons, creating a classic and formal
            representation of your brand.
            <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Wordmarks:
                </h2>
                <p className="meta-large-para !text-textWhite">
                  METANADO specializes in creating wordmarks that visually
                  transform your brand name, making it instantly recognizable
                  and memorable through striking typographic design.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-9%",
                }}
              >
                <img
                  src={logosData[4].images[0]}
                  // src={menu[0].image}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />
              <div className="pt-2"></div>
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Lettermarks:
                </h2>{" "}
                <p className="meta-large-para !text-textWhite">
                  we specialize in creating polished and professional logos by
                  incorporating your business's initials, which are ideal for
                  cultivating a refined and elegant brand identity.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-7%",
                }}
              >
                <img
                  // src={menu[0].image}
                  src={logosData[4].images[1]}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />
              <div className="pt-2"></div>
              <li>
                {" "}
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Brandmarks:
                </h2>
                <p className="meta-large-para !text-textWhite">
                  The brandmarks of METANADO use distinctive symbols or icons to
                  embody your business, establishing a powerful visual
                  connection without depending on text.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-6%",
                }}
              >
                <img
                  // src={menu[0].image}
                  src={logosData[4].images[2]}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Combination Marks:
                </h2>{" "}
                <p className="meta-large-para !text-textWhite">
                  METANADO's combination marks combine text and symbols to craft
                  versatile logo that clearly conveys your brand's identity.
                  Emblems: At METANADO, our emblems combine text with symbols or
                  icons, creating a classic and formal representation of your
                  brand.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-7%",
                }}
              >
                <img
                  // src={menu[0].image}
                  src={logosData[4].images[3]}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
            </ul>
          </p>
        </ScreenContainer>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-0 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h2 className="meta-subheading">Full Branding Packages</h2>
              <p className="meta-large-para  w-auto ">
                Are you a new business or a well-established one? We offer a
                full range of branding materials to enhance your visibility. Our
                services include creating logos, letterheads, and business
                cards, as well as designing branded apparel, vehicle wraps, and
                signage. We've got all your digital and physical branding needs
                covered to ensure your brand leaves a lasting impression. We
                make sure to grasp your business, target audience, and
                objectives through our teamwork, producing designs that are not
                only visually appealing but also produce tangible outcomes.
              </p>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="grid md:grid-cols-2 sm:grid-cols-2 grid-cols-1  gap-[60px]">
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Marketing Materials
                </h4>
                <p className="meta-large-para">
                  We've got all your marketing material needs covered at
                  METANADO. From posters and flyers to signage and business
                  cards, we create professional email signatures, letterheads,
                  and advertisements to give your business the promotion it
                  deserves. Let us help boost your brand's visibility today!
                </p>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Social Media Assets
                </h4>
                <p className="meta-large-para">
                  At METANADO, we're really good at making awesome content for
                  your social media. We make sure your brand's message is always
                  strong, interesting, and stands out from the competition.
                </p>
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>
      {/* ====================== our branding strategies  */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-0 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h2 className="meta-subheading">Our Branding Expertise</h2>
              <p className="meta-large-para  w-auto ">
                At METANADO, we wrap up our branding process by providing you
                with a detailed brand guide. This guide is essential for
                developing a promotional website and other design components. It
                features brand illustrations that effectively showcase your new
                company image, guaranteeing a consistent and powerful presence
                on all platforms. .
              </p>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px] mb-[40px] sm:mb-[50px] lg:mb-[80px]">
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Brand identity
                </h4>
                <ul className="list-disc pl-[15px] meta-large-para">
                  <li>Visual Positioning</li>
                  <li>Visual Identity </li>
                  <li>Iconography & Illustration Guidelines</li>
                  <li>Brand Application and Implementation</li>
                </ul>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Brand strategy
                </h4>
                <ul className="list-disc pl-[15px] meta-large-para">
                  <li>Brand Equity Assessment</li>
                  <li>Target Audience Analysis</li>
                  <li>Competitor Review</li>
                  <li>Strategic Planning</li>
                </ul>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Communication strategy
                </h4>
                <ul className="list-disc pl-[15px] meta-large-para">
                  <li>Verbal Identity Development</li>
                  <li>Tagline Creation</li>
                  <li>Communication Style Guide</li>
                </ul>
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>
      {/* ========================================== */}

      <div
        className="white-sec pb-8 flex flex-col gap-[40px] bg-gray-100 "
        id="why-choose-us-linker"
      >
        <div>
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <div className="flex justify-start items-start">
                  <img src={arrow} alt="Arrow" className="w-[5%]" />
                  <h4
                    className="meta-paragraph"
                    style={{ textTransform: "uppercase" }}
                  >
                    {"choose us"}
                  </h4>
                  <img
                    src={arrow}
                    alt="Arrow"
                    className="w-[5%] transform rotate-180"
                  />
                </div>
                <h2
                  className="meta-subheading !text-start "
                  style={{ textTransform: "capitalize" }}
                >
                  Why METANADO?
                </h2>
                <p
                  className="meta-large-para !text-start w-full"
                  style={{ fontWeight: "400" }}
                >
                  METANADO is the top pick for branding design because we have a
                  unique skill set in creating impactful and unforgettable brand
                  identities, especially for innovative companies. We are
                  experts in providing comprehensive branding solutions, from
                  design to strategy, making sure your brand shines in a
                  competitive market
                </p>
              </div>
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo} alt="Logo" className="w-auto h-[60px]" />
              </div>
            </div>
          </ScreenContainer>
        </div>
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
      </div>

      {/* component */}

      {/* component */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-2 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <h2 className="meta-subheading"> Branding Clients</h2>
                <p className="meta-large-para  w-auto ">
                  We've partnered with a variety of well-known brands and
                  cutting-edge startups backed by top venture capital firms like
                  Sequoia Capital, Benchmark, and Lightspeed Venture Partners.
                </p>
              </div>

              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo3d} alt="Logo" className="w-auto rounded-lg" />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="  w-full  bg-gray-100 "
      >
        <ScreenContainer>
          <div className="flex flex-wrap justify-start">
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company1}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company16} alt="cafex logo" />
            </div>
          </div>
        </ScreenContainer>
      </motion.section>

      {/* sections */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-2 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <h2 className="meta-subheading">Get Results with METANADO</h2>
                <p className="meta-large-para w-auto">
                  We at METANADO specialize in crafting eye-catching brochures
                  and flyers that make a strong impact. Our talented team
                  produces visually stunning materials that effectively convey
                  your brand’s message and captivate your intended audience.
                  Whether you require a standout brochure or a
                  attention-grabbing flyer, we guarantee that your promotional
                  materials will stand out and connect with your customers.
                </p>
              </div>

              {/* Logo div */}
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo3d2} alt="Logo" className="w-auto rounded-lg" />
              </div>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px] ">
                <div className="flex flex-col justify-start items-start gap-[10px] ">
                  <ul className="list-disc pl-[15px] meta-large-para">
                    <li>Customized Design </li>
                    <li>High-Quality Print</li>
                    <li>Versatile Formats</li>
                    <li>Print Readiness</li>
                  </ul>
                </div>
              </div>
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img
                  src={logosData[4].images[0]}
                  alt="Logo"
                  className="w-auto "
                />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div className="bg-[#F1F1F1] ">
          <div className="flex flex-col justify-center items-center py-10 w-[100%]">
            <TitleWithSubTitleDesc data={testimonialsHomeTitleParaA} />
            <ScreenContainer>
              <div className="slier-section-testimonials my-10">
                <TestimonialSlider data={testimonial} />
              </div>
            </ScreenContainer>

            {/* <TitleWithSubTitleDesc data={testimonialsHomeTitleParaB} /> */}
          </div>
        </div>
      </motion.section>

      {/* FAQ */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="flex  my-10 bg-white text-[#141414]"
      >
        <Accordion data={designfaqs} />
      </motion.section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Designing;
