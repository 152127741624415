import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScreenContainer from "../ScreenContainer";
import { FaRegComments } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import { IoMdArrowDropleftCircle } from "react-icons/io";
import mobileBg from "../../assets/images/sec4_right_bg.webp";
import img1 from "../../assets/images/sec4_right_item1.webp";
import img2 from "../../assets/images/sec4_right_item2.webp";
import img3 from "../../assets/images/sec4_right_item3.webp";
const MobileAppsSlider = () => {
  const sliderRef = useRef<Slider | null>(null);
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  const navigate = useNavigate();
  return (
    <div className="w-full h-full relative">
      <ScreenContainer>
        <div className="flex md:flex-row flex-col gap-[60px] sm:gap-[80px] lg:gap-0">
          <div className="md:w-[90%] w-full">
            <div className="sec4_lef">
              <div className="px-3">
                <h1 className="meta-subheading mb-2">
                  Mobile Apps For Better Business Growth
                </h1>
                <p className="meta-paragraph mb-2">
                  Grow and expand your business with our Mobile App Solutions.
                  Our Mobile Application Services are backed by years of
                  experience, creative minds, and a bond of trust with our
                  clientele.
                </p>
                <div className="link-area pt-6">
                  {" "}
                  <a
                    href="javascript:void(0)"
                    className="simple-a"
                    rel="nofollow"
                  >
                    <i className="far fa-comments">
                      <FaRegComments size={25} />
                    </i>
                    Want to discuss?
                  </a>{" "}
                  <a
                    className="hover-effect cursor-pointer"
                    onClick={() => navigate("/contact-us")}
                  >
                    Let's Start<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[55%] w-full justify-end items-end relative">
            {/* <div className="slick-mob-btns absolute w-full top-[45%] z-10 justify-between flex flex-row">
              <button
                onClick={handlePrev}
                className="slick-mob-prev-btn w-[28px] h-[28px] md:absolute relative sm:left-[20%]"
              >
                <IoMdArrowDropleftCircle className="w-full h-full text-[#643db4]" />
              </button>
              <button
                onClick={handleNext}
                className="slick-mob-next-btn w-[28px] h-[28px] md:absolute relative sm:right-[30%]"
              >
                <IoMdArrowDroprightCircle className="w-full h-full text-[#643db4]" />
              </button>
            </div> */}
            <div className="lg:w-[90%] slick-slider-mobile lg:ml-auto ml-[10px]">
              <div className="sec4_right">
                <div className="mobile-img absolute">
                  <img src={mobileBg} className="w-full h-full" />
                </div>
                <Slider ref={sliderRef} {...settings}>
                  <div className="item item1">
                    <img
                      loading="lazy"
                      src={img1}
                      alt="Loading Image..."
                      className="mobile-slider-img"
                    />
                  </div>
                  <div className="item item2">
                    <img
                      loading="lazy"
                      src={img2}
                      alt="Loading Image..."
                      className="mobile-slider-img"
                    />
                  </div>
                  <div className="item item3">
                    <img
                      loading="lazy"
                      src={img3}
                      alt="Loading Image..."
                      className="mobile-slider-img"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </ScreenContainer>
    </div>
  );
};

export default MobileAppsSlider;
