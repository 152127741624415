import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { IoIosStar } from "react-icons/io";
import {
  aboutServiceParaA,
  aboutTestimonialParaA,
  testimonial,
} from "../strings/Array";
import Chat from "../file/Chat";
import { useNavigate } from "react-router-dom";
import TestimonialSlider from "./sliders/TestimonialSlider";
import TitleWithSubTitleDesc from "./TitleWithSubTitleDesc";
import ScreenContainer from "./ScreenContainer";

const About = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Header />

      <div className="inner-banner about-banner">
        <div className="banner-line flex justify-center items-center">
          <div className="container position-rel-container">
            <div className="exta-div"></div>
          </div>
        </div>
        <div className="animate-box banner-bottom-left-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-left-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-left-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-left-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="animate-box banner-bottom-left-5 onload-animate fadeIn animation-delay-25ms"></div>
        <div className="animate-box banner-bottom-right-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-right-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-right-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-right-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="black-overlay"></div>
        {/* <ScreenContainer> */}

        <div className=" absolute-center w-full">
          <ScreenContainer>

            <div className="">
              <div className="flex flex-col gap-[20px]">
                <h1 className="meta-heading">
                  We Fuel Dreams. <br />
                  You Witness Success!
                </h1>
                <p className="meta-large-para !text-white w-full md:w-[70%]">
                  At METANADO, we aim to bring our clients' visions to life by
                  creating unique digital experiences that help them stand out
                  in the market!
                </p>
              </div>
            </div>
            </ScreenContainer>
            </div>
        {/* </ScreenContainer> */}
      </div>

      <div className="white-sec pb-0" id="what-we-do-linker">
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style={visibility: visible; animation-name: fadeIn;}></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style={visibility: visible; animation-name: fadeIn;}></div> */}
        <ScreenContainer>
          <div className="md:flex gap-3">
            <div className="col-lg-6">
              <div className="section-heading left-heading flex flex-col justify-center md:justify-start md:items-start items-center md:text-start text-center">
                <h5 className="meta-paragraph">WHAT WE DO</h5>
                <h2 className="meta-subheading">
                  Pioneers in Design and Development
                </h2>
                <h6 className="meta-large-para">
                  We Enhance Your Vision with <br />
                  Creative Brilliance!
                </h6>
                <p className="meta-large-para">
                  At METANADO, we strive to go above and beyond our clients'
                  expectations. Our team of innovative experts is prepared to
                  breathe life into your ideas, be it through website design and
                  development, logo design, branding, or marketing services.
                </p>
                <div className="link-area">
                  {" "}
                  <a
                    className="hover-effect"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="about-second-sec-img second-sec-hover ipadmargin30">
                {" "}
                <img
                  src={require("../assets/images/about-second-sec.jpg")}
                  alt=""
                  className="about-second-img"
                />
                <div className="work-video-popup">
                  {" "}
                  <img
                    src={require("../assets/images/video-play-btn-black.png")}
                    alt=""
                  />{" "}
                  <span className="before"></span>{" "}
                  <span className="after"></span>{" "}
                </div>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div className="white-sec" id="mission-vision-linker">
        <ScreenContainer>
          <div className="flex flex-col gap-[20px] my-[20px]">
            <div className="section-heading">
              <h5>SERVICES WE OFFER</h5>
              <h2 className="meta-subheading">
                Fulfilling our clients' digital needs <br />
                for years on end!
              </h2>
              <p className="meta-large-para !text-center mt-2">
                We provide top-notch digital solutions to help our clients
                succeed in the online world at METANADO.
                <br />
                Our goal is to empower them with innovative designs that set
                them apart from the competition.
              </p>
            </div>
          </div>
        </ScreenContainer>

        <div className="lg:py-[80px] sm:py-[50px] py-[40px] bg-gray-100">
          {/* <div className="animate-box section-back-top-left-3 wow fadeIn animation-delay-5ms hide-on-mob" style={{visibility: 'visible' , animation-name: 'fadeIn'}}></div> */}
          {/* <div className="animate-box section-back-top-left-4 wow fadeIn animation-delay-10ms hide-on-mob" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-back-top-left-5 wow fadeIn animation-delay-15ms hide-on-mob" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="about-service-1 displaynoneonipad"> </div> */}
          <ScreenContainer>
            <div className="md:flex gap-3">
              <div className="lg:w-[50%] md:w-[70%] w-auto">
                <div className=" left-heading flex flex-col justify-center md:justify-start md:items-start items-center md:text-start text-center">
                  <h5 className="meta-paragraph">Our Mission &amp; Vision</h5>
                  <h2 className="meta-subheading">Our Mission &amp; Vision</h2>
                  <p className="meta-large-para">
                    At METANADO, we are dedicated to creating exceptional
                    designs and experiences as a leading digital agency. <br />
                    <br /> Our goal is to offer unparalleled value through our
                    innovative and creative services, all at competitive prices.
                    With our expertise in branding, marketing, and design, we
                    help businesses establish a powerful online presence that
                    truly connects with their target audience. <br />
                    <br /> We are firm believers that every brand, no matter its
                    size, should have the chance to stand out in the digital
                    realm. METANADO is here to assist those who are eager to
                    succeed and leave a lasting impression in an ever-changing
                    environment.{" "}
                  </p>
                  <div className="link-area mt-[40px]">
                    {" "}
                    <a href="javascript:void(0)" className="simple-a">
                      <i className="far fa-comments"></i>Live Chat
                    </a>{" "}
                    <a
                      onClick={() => navigate("/pricing")}
                      className="hover-effect live-cht"
                    >
                      Start Your Project
                      <span className="flash-glow-effect"></span>
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="w-auto md:pb-0 pb-3">
                <img
                  src={require("../assets/images/about-1.jpg")}
                  alt="About-Image"
                />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </div>

      <div className="white-sec pb-0 flex flex-col gap-[40px]" id="why-choose-us-linker">
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        <ScreenContainer>
          <TitleWithSubTitleDesc
            data={aboutServiceParaA}
            bgColor={"transparent"}
          />
        </ScreenContainer>
        <ScreenContainer>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px] mb-[40px] sm:mb-[50px] lg:mb-[80px]">
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/settings.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">
                Years Of Experience
              </h4>
              <p className="meta-paragraph text-center">
                At METANADO, our team of creative geniuses has been leading the
                charge in transforming brands into digital powerhouses for many
                years. Our commitment to innovation fuels success and fosters
                growth.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/support.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">
                Full-Service Studio
              </h4>
              <p className="meta-paragraph text-center">
                Look no further than METANADO for all your digital needs. From
                designing websites and creating logos to developing brands and
                marketing strategies, we offer a comprehensive range of services
                to enhance your brand.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/briefcase.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">Quality Work</h4>
              <p className="meta-paragraph text-center">
                We take great pride in delivering top-notch, unparalleled
                services that distinguish us within the digital landscape. Our
                dedication to perfection ensures that our clients receive only
                the finest quality.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/low-price.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">Affordable</h4>
              <p className="meta-paragraph text-center">
                Our cost-effective packages make digital achievements achievable
                for everyone. Our goal is to assist brands in reaching their
                objectives without exceeding their financial limits, delivering
                exceptional value every step of the way.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/fast-delivery.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">
                {" "}
                Quick Turnaround Time
              </h4>
              <p className="meta-paragraph text-center">
                Our proficient team guarantees rapid project turnaround times.
                By merging efficiency with creativity, we deliver exceptional
                results promptly.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center gap-[10px]">
              <img
                src={require("../assets/images/technology.png")}
                alt="Creative"
                className="w-[70px]"
              />
              <h4 className="meta-nav-heading !font-bold">
                {" "}
                Latest Technology
              </h4>
              <p className="meta-paragraph text-center">
                METANADO is fully equipped with advanced technology and tools.
                Our advanced software and robust machinery allow us to bring
                your digital dreams to reality with accuracy and excellence.
              </p>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div className="bg-[#F1F1F1] mt-10 py-10">
        <div className="flex flex-col justify-center items-center gap-[40px]">
          <TitleWithSubTitleDesc data={aboutTestimonialParaA} />
          <ScreenContainer>
            <TestimonialSlider />
          </ScreenContainer>
          <div className="link-area">
            <a
              className="hover-effect cursor-pointer"
              onClick={() => navigate("our-portfolio")}
            >
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>
          </div>
        </div>
      </div>
      <Chat />

      <Footer />
    </div>
  );
};

export default About;
