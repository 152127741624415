import { useRef, useEffect } from "react";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import DesignCard from "../../component/DesignCard/DesignCard";
import { marketing, brandfaqs, logosData } from "../../strings/Array";
import Accordion from "../../component/FAQ";
import { motion, useAnimation, useInView } from "framer-motion";
import { testimonialBrand } from "../../strings/Array";
import company1 from "../../assets/company3/1582115752-logobitmoji.svg";
import company2 from "../../assets/company3/1582115753-logocafex.svg";
import company3 from "../../assets/company3/1582115754-logocellebrite.svg";
import company4 from "../../assets/company3/1582115762-logodescript.svg";
import company5 from "../../assets/company3/1582115768-logoflyr.svg";
import company6 from "../../assets/company3/1582115790-logokyber-network.svg";
import company7 from "../../assets/company3/1582115792-logomark-logic.svg";
import company8 from "../../assets/company3/1582115797-logomozilla.svg";
import company9 from "../../assets/company3/1582115800-logonetflix.svg";
import company10 from "../../assets/company3/1582115801-logoopera.svg";
import company11 from "../../assets/company3/1582115802-logooppo.svg";
import company12 from "../../assets/company3/1582115805-logoosmo.svg";
import company13 from "../../assets/company3/1628084534-truebill.svg";
import company14 from "../../assets/company3/1582115826-logouniversal-music.svg";
import company15 from "../../assets/company3/1582115827-logovolusion.svg";
import company16 from "../../assets/company3/1582115832-logowyre.svg";
import logo from "../../assets/images/main-logo.png";
import logo3d from "../../assets/images/logo-inner-img.gif";
import logo3d2 from "../../assets/images/logo-inner-img3_1.gif";
import TitleWithSubTitleDesc from "../../component/TitleWithSubTitleDesc";
import Project from "../../component/BrandProject";
import WorkHome from "../../LogoWork";
import { useMediaQuery } from "react-responsive";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";

import menu from "../../strings/Array";
import TestimonialSlider from "../../component/sliders/TestimonialSlider";
import "./Digital_marketing.css";
import ScreenContainer from "../../component/ScreenContainer";
import { testimonial } from "../../strings/Array";
import Work from "../VideoWork";
import { testimonialsHomeTitleParaA } from "../../strings/Array";
import arrow from "../../assets/images/arrow_1.svg";
import { useNavigate } from "react-router-dom";

export default function Digital_marketing() {
  const ref = useRef<HTMLDivElement>(null); // Initialize ref with null
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  // Pass ref.current to useInView
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const fadeInAnimationVarinats = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  const fadeInAnimationVarinas = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: (index: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25 * index,
        duration: 1,
      },
    }),
  };

  const fadeInAnimationVarinat = {
    initial: {
      scale: 0,
      rotate: 90,
    },
    animate: (index: any) => ({
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 0.5,
      },
    }),
  };

  return (
    <div className="w-full ">
      {/* <div className="fixed top-0 z-10"> */}
      <Header />
      {/* </div> */}

      <div className="bg-primary py-[40px] sm:py-[50px] lg:py-[80px] !pb-[10px]">
        <ScreenContainer>
          <div className="flex gap-[20px]">
            <div className="w-auto flex flex-col gap-[20px]">
              <div>
                <h5 className="meta-large-para !text-textWhite">
                  Get Recognized in a Blink of an Eye
                </h5>
                <h1 className="meta-subheading !text-textWhite">
                  <span>Showcasing </span> Brand
                  <br /> Messages Through
                  <span className="bnr-spn-clr"> Captivating Videos</span>{" "}
                </h1>
              </div>
              <h4 className="meta-large-para !text-textWhite">
                Attractive animated videos that set you apart from the
                competition and give your customers another reason to trust you.
              </h4>
              <ul className="meta-large-para !text-textWhite">
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  More than 12 Years Experience
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Clients Across 45 Industries
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Money-Back Guarantee
                </li>
                <li>
                  <i className="fa" aria-hidden="true">
                    <FaCheckCircle />
                  </i>{" "}
                  Industry Specific Designers
                </li>
              </ul>
              <div className="bnr-btn">
                <a
                  // onClick={scrollToPriceTb1}
                  className="get-strtd popup-btn cursor-pointer"
                >
                  Get Started
                  <i className="fa fa-power-off" aria-hidden="true"></i>
                </a>
                <a
                  onClick={() => navigate("/contact-us")}
                  className="live-cht chats !text-textWhite"
                >
                  Live Chat{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <h6 className="meta-large-para !text-textWhite">
                Implementation process is easy and completly free. Contact us
                for further info
              </h6>
            </div>
            <div className="w-auto ">
              {/* bnr-img-st */}
              <div className=" hidden lg:block !bg-transparent">
                <img
                  className=" transition-transform duration-300 ease-in-out hover:scale-110 w-full"
                  src={"https://www.prismedium.com/images/video-bnr-1-img.png"}
                />
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <ScreenContainer>
        <div className=" w-auto mx-auto flex justify-center items-center "></div>
        <div className="flex flex-col  w-auto mx-auto py-[40px] sm:py-[50px] lg:py-[80px]">
          {/* {menu.map((menu, id) => {
              return ( */}
          <div
            className="fadeIn"
            style={{
              display: true ? "block" : "none",
            }}
          >
            <div className="md:flex  justify-between gap-[20px]">
              <div className="md:w-[50%] w-auto flex flex-col  ">
                {/* <div className="flex gap-3">
                      <img src={arrow} alt="Arrow" className="w-[10%]" />
                      <h1 className="meta-heading-label">{menu.title}</h1>
                    </div> */}
                <h1 className="meta-subheading">{menu[2].heading}</h1>
                <h2 className="meta-heading-label !text-secondary">
                  {menu[2].desc}
                </h2>
                <p className="meta-paragraph mt-[10px]">{menu[2].para}</p>
                <p className="meta-paragraph mt-[10px]">{menu[2].para2}</p>
              </div>
              <div className="md:w-[50%] w-auto flex justify-center items-center mx-auto mt-[30px]  md:mt-0">
                <img src={menu[2].image} alt="Image" width={"100%"} />
              </div>
            </div>
          </div>
          {/* );
            })} */}
        </div>
      </ScreenContainer>

      <ScreenContainer>
        <TitleWithSubTitleDesc
          bgColor="white"
          data={{
            // subTitle: " OUR AWESOME WORK",
            mainTitle: " A snippet of our portfolio from our database.",
            description:
              "CanDigitals have provided value-based services throughout the world. Over the years, we have served more than 5000+ clients throughout the world.",
          }}
        />

        <Work />
      </ScreenContainer>
      {!isMobile && <Project />}

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className=" bg-[#141414] text-[#141414] justify-center flex-col  md:justify-start py-[40px] sm:py-[50px] lg:py-[80px]"
      >
        <ScreenContainer>
          <h2 className="meta-subheading !text-textWhite mb-[20px]">
            Organic Marketing
          </h2>

          <p className="meta-large-para !text-textWhite">
            Build lasting relationships and credibility with our organic
            marketing services. We focus on sustainable strategies to enhance
            your online presence and attract organic traffic. Create strong
            connections and trust with our organic marketing services. We
            concentrate on long-term tactics to improve your online visibility
            and attract organic visitors.
            <ul className="text-2xl pt-8 w-full md:w-4/6 hyphens-auto list-disc ml-5">
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Search Engine Optimization (SEO):
                </h2>
                <p className="meta-large-para !text-textWhite">
                  Boost your website's presence on search engines by optimizing
                  keywords, enhancing on-page SEO, and implementing technical
                  SEO strategies.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-9%",
                }}
              >
                <img
                  src={logosData[4].images[0]}
                  // src={menu[0].image}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Content Marketing:
                </h2>{" "}
                <p className="meta-large-para !text-textWhite">
                  Create top-notch content that connects with your audience and
                  boosts your website's organic traffic flow.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "-4%",
                }}
              >
                <img
                  // src={menu[0].image}
                  src={logosData[4].images[1]}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />

              <li>
                {" "}
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Blog Management:
                </h2>
                <p className="meta-large-para !text-textWhite">
                  Regularly update your blog with relevant, engaging content to
                  improve search rankings and audience engagement.
                </p>
              </li>
              <div
                className="md:w-1/4 flex justify-center items-center mt-[30px] md:mt-0"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: "40px",
                  marginTop: "0%",
                }}
              >
                <img
                  // src={menu[0].image}
                  src={logosData[4].images[2]}
                  alt="Image"
                  className="w-full md:w-auto max-w-[150px]"
                />
              </div>
              <br />
              <li>
                <h2 className="meta-nav-heading !font-bold !text-textWhite">
                  Link Building:
                </h2>{" "}
                <p className="meta-large-para !text-textWhite">
                  Boost your website's credibility through strategic
                  link-building strategies.{" "}
                </p>
              </li>
            </ul>
          </p>
        </ScreenContainer>
      </motion.section>

      <div
        className="white-sec flex flex-col gap-[40px] light-purple-bg"
        id="why-choose-us-linker"
      >
        <div>
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <div className="flex justify-start items-start">
                <img src={arrow} alt="Arrow" className="w-[5%]" />
                <h4
                  className="meta-paragraph"
                  style={{ textTransform: "uppercase" }}
                >
                  {"choose us"}
                </h4>
                <img
                  src={arrow}
                  alt="Arrow"
                  className="w-[5%] transform rotate-180"
                />
              </div>
              <h2
                className="meta-subheading !text-start"
                style={{ textTransform: "capitalize" }}
              >
                Why Choose Digital Marketing at METANADO?
              </h2>
              <p
                className="meta-large-para !text-start w-full"
                style={{ fontWeight: "400" }}
              >
                Why Choose Digital Marketing at METANADO?When you choose
                METANADO for digital marketing services, you're teaming up with
                a dedicated group focused on your brand's growth and success. We
                blend innovative strategies with data-driven insights to develop
                personalized marketing campaigns that resonate with your target
                audience. Whether it's through precise paid ads, sustainable
                organic growth tactics, or engaging social media content, our
                comprehensive approach ensures maximum visibility and impact.
                Our commitment to excellence, creativity, and measurable results
                makes METANADO the top choice for businesses looking to thrive
                in the digital landscape.
              </p>
            </div>
          </ScreenContainer>
        </div>
        {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
        {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
      </div>
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-0 flex flex-col gap-[40px] !p-0 light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start ">
              <h2 className="meta-subheading"> Get Results with METANADO</h2>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-between w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <ul className="list-disc pl-[15px] meta-large-para">
                  <li>Enhanced Online Presence</li>
                  <li>Engaged Audience</li>
                  <li>Data-Driven Decisions</li>
                  <li>Custom Solutions</li>
                  <li>Increased Brand Loyalty</li>
                  <li>Agile Adaptation</li>
                </ul>
              </div>
              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo3d} alt="Logo" className="w-auto rounded-lg " />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h2 className="meta-subheading"> Branding Clients</h2>
              <p className="meta-large-para  w-auto ">
                Established brands and growing businesses backed by Y
                Combinator, Khosla Ventures, Accel Partners, Andreessen Horowitz
                and others.
              </p>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="  w-full  bg-gray-100  "
      >
        <ScreenContainer>
          <div className="flex flex-wrap justify-start">
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company1}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company2}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company3}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company4}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company5}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company6}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company7}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company8}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company9}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company10}
                alt="cafex logo"
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img
                src={company11}
                alt="cafex logo "
                className="object-fill w-full h-full"
              />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company12} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company13} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company14} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company15} alt="cafex logo" />
            </div>
            <div className="w-1/2   sm:w-1/3 md:w-1/4">
              <img src={company16} alt="cafex logo" />
            </div>
          </div>
        </ScreenContainer>
      </motion.section>
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-0 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-row justify-between lg:justify-around w-full">
              <div className="flex flex-col justify-start items-start gap-[10px] w-full lg:w-[60%]">
                <h2 className="meta-subheading"> Why Ramotion?</h2>
                <p className="meta-large-para  w-auto ">
                  Ramotion is a preferred choice for branding design due to our
                  specialized expertise in creating dynamic and impactful brand
                  identities, especially for technology companies. We are excel
                  in developing comprehensive branding solutions, encompassing
                  brand design, strategy, and identity creation.
                </p>
              </div>

              <div className="hidden lg:flex items-center justify-center w-full lg:w-[40%]">
                <img src={logo3d2} alt="Logo" className="w-auto rounded-lg" />
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>

      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div
          className="white-sec pb-2 flex flex-col gap-[40px] light-purple-bg"
          id="why-choose-us-linker"
        >
          {/* <div className="animate-box section-top-right-3 wow fadeIn animation-delay-5ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          {/* <div className="animate-box section-top-right-4 wow fadeIn animation-delay-10ms" style="visibility: visible; animation-name: fadeIn;"></div> */}
          <ScreenContainer>
            <div className="flex flex-col justify-start items-start gap-[10px]">
              <h2 className="meta-subheading"> Articles about branding</h2>
            </div>
          </ScreenContainer>
          <ScreenContainer>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-[60px] ">
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Local Branding
                </h4>
                <div className="flex flex-col h-full justify-between">
                  <p className=" meta-large-para">
                    Local branding offers numerous benefits for the companies.
                    Dive into its definition, strategies, and examples to see
                    how it can help you achieve success.
                  </p>
                  <p className="mt-[10px]"> ⏲ 12 min read</p>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px] ">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Global Branding
                </h4>
                <div className="flex flex-col h-full justify-between">
                  <p className=" meta-large-para">
                    Global branding is a dream for many companies. However, is
                    it time for you? Consider its basics, pros, and cons to see
                    if it fits your brand right now.
                  </p>
                  <p className="mt-[10px]"> ⏲ 20 min read</p>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-[10px]">
                <h4 className="meta-nav-heading !font-bold !text-start">
                  Gorporate Branding
                </h4>
                <div className="flex flex-col h-full justify-between">
                  <p className=" meta-large-para">
                    Corporate branding is a vital marketing concept that helps
                    companies secure a strong position in the market. Dive into
                    its essentials to make it work for you
                  </p>
                  <p className="mt-[10px]"> ⏲ 17 min read</p>
                </div>
              </div>
            </div>
          </ScreenContainer>
        </div>
      </motion.section>
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
      >
        <div className="bg-[#F1F1F1] ">
          <div className="flex flex-col justify-center items-center py-10 w-[100%]">
            <TitleWithSubTitleDesc data={testimonialsHomeTitleParaA} />
            <ScreenContainer>
              <div className="slier-section-testimonials my-10">
                <TestimonialSlider data={testimonial} />
              </div>
            </ScreenContainer>

            {/* <TitleWithSubTitleDesc data={testimonialsHomeTitleParaB} /> */}
          </div>
        </div>
      </motion.section>
      {/* component */}

      {/* separate component */}

      {/* FAQ */}
      <motion.section
        variants={fadeInAnimationVarinats}
        initial="initial"
        whileInView="animate"
        viewport={{
          once: true,
        }}
        className="flex my-10 bg-white text-[#141414]"
      >
        <Accordion data={brandfaqs} />
      </motion.section>

      <section>
        <Footer />
      </section>
    </div>
  );
}
