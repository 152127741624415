import React, { useState } from "react";
import logo10 from "../assets/images/logo-1-1.png";
import logo11 from "../assets/images/logo-2-1.png";
import logo12 from "../assets/images/logo-3-1.png";
import logo13 from "../assets/images/logo-4-1.png";
import logo14 from "../assets/images/logo-5-1.jpg";
import logo15 from "../assets/images/logo-6-1.png";
import logo16 from "../assets/images/logo-7-1.png";
import logo17 from "../assets/images/logo-8-1.png";
import logo18 from "../assets/images/logo-9-1.png";
import { FaPlay } from "react-icons/fa";

const WorkHome = () => {
  const [activeCategory, setActiveCategory] = useState("logo");
  const [activelogo, setActivelogo] = useState();

  const handleTabClick = (id: any) => {
    setActiveCategory(id);
  };

  return (
    <div>
      <div className="col-sm-12 flex justify-center items-center w-[100%] ">
        <div className="port-nav-tb cursor-pointer py-6 w-full   sm:px-[15px] px-0 ">
          <ul
            id="nav-tabs-wrapper"
            className="nav nav-tabs md:flex justify-center items-center gap-[8px]"
          >
            <li className={activeCategory === "logo" ? "active" : ""} >
              <a
                className="px-[20px] py-[8px]"
                onClick={() => handleTabClick("logo")}
                id="portfolio_logo"
              >
                Design
              </a>
            </li>

            <li className={activeCategory === "website" ? "active" : ""}>
              <a
                className="px-[20px] py-[8px]"
                onClick={() => handleTabClick("website")}
                id="portfolio_website"
              >
                Website
              </a>
            </li>

            <li className={ `${activeCategory === "brand" ? "active" : ""}`  }>
              <a
                className="px-[20px] py-[8px] "
                onClick={() => handleTabClick("brand")}
                id="portfolio_brand"
              >
                Branding
              </a>
            </li>

            {/* <li className={activeCategory === "video" ? "active" : ""}>
              <a
                className="p-3"
                onClick={() => handleTabClick("video")}
                id="portfolio_video"
              >
                Video
              </a>
            </li> */}
          </ul>
        </div>
      </div>

      {activeCategory === "logo" && (
        <div
          id="portfolio_logo"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3   w-[100%] h-[90%]"
        >
          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-1.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo10}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-20 lg:pb-8 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-2.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo11}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-3 ml-16 lg:pb-16 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-3.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo12}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 ml-12 lg:pb-16 pt-12 pb-8 px-8 lg:w-[80%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-4.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo13}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 xl:ml-40 lg:ml-28 md:ml-20 ml-28 lg:pb-16 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-5.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo14}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 lg:pb-16 pt-12 pb-8 px-8 lg:w-[45%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[70%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-6.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover "
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo15}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 lg:pb-16 pt-12 pb-8 px-8 lg:w-[75%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-7.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover "
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo16}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[75%] w-[70%] h-[100%] md:w-[70%] md:h-[70%] lg:h-[85%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-8.jpg")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover "
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo17}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/logo-9.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={logo18}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-20 lg:pb-12 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      )}

      {activeCategory === "brand" && (
        <div
          id="portfolio_brand"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3 w-[100%] h-[90%]"
        >
          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-1.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-1-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 ml-16 lg:pt-12 lg:pb-12 pt-12 pb-8 px-8 lg:w-[45%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-2.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-2-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-16 ml-16 lg:pb-0 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[60%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-3.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-3-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 ml-12 lg:pb-16 pt-12 pb-8 px-8 lg:w-[40%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[80%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-4.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-4-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-6 xl:ml-40 lg:ml-28 md:ml-20 ml-28 lg:pb-16 pt-12 pb-8 px-8 lg:w-[35%] w-[35%] h-[30%] md:w-[70%] md:h-[100%] lg:h-[30%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-5.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-5-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 mr-12 hover:scale-110 lg:pt-16 lg:pb-10 pt-12 pb-8 px-8 lg:w-[45%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] flex justify-start items-start"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-6.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover "
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-6-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-16 lg:pb-2 pt-12 pb-8 px-8 lg:w-[75%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-7.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-7-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[60%] w-[70%] h-[100%] md:w-[70%] md:h-[70%] lg:h-[85%] flex justify-end items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-8.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-8-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-48 lg:pb-0 pt-12 pb-8 px-8 lg:w-[65%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[90%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div
              style={{
                backgroundImage: `url(${require("../assets/images/branding-img-9.png")})`,
              }}
              className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-end items-center"
            >
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/branding-img-9-1.jpg")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 lg:pt-20 lg:pb-12 pt-12 pb-8 px-8 lg:w-[55%] w-[70%] h-[100%] md:w-[70%] md:h-[100%] lg:h-[50%] flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      )}

      {activeCategory === "website" && (
        <div
          id="portfolio_website"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3 w-[100%] h-[90%]"
        >
          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/1-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/2-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/3-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/4-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/5-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/6-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/7-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover ">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/8-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>

          <div className="w-[100%] h-[100%] mx-auto">
            <div className="w-[100%] h-[100%] bg-no-repeat bg-cover flex justify-center items-center">
              {/* <img src={logo1} alt="Image"  /> */}
              <img
                src={require("../assets/images/9-1.png")}
                alt="Image"
                className="transform transition-transform duration-300 scale-100 hover:scale-110 px-8 w-[100%] h-[100%] flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      )}

      {activeCategory === "video" && (
        <div
          id="portfolio_video"
          className="grid md:grid-cols-3 grid-cols-1 sm:grid-cols-2 justify-center items-center my-3 mt-6 gap-3 w-[100%] h-[90%] "
        >
          <div className="flex ">
            <div className="port-img box12 w-full">
              <a
                href="https://youtu.be/3kziPIpUZy8"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_1.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/gMuLoKl4qyo"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_2.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/qEkVk_I99HI"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_3.jpg")}
                />
                <div className="box-content">
                  <ul className="icon text-white">
                    <li>
                      <i className="fa " aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex ">
            <div className="port-img box12 w-full">
              <a
                href="https://youtu.be/GlybjPXX0Xo"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_4.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa " aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/CnSyth3A-hw"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_5.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/SNTzZh58YeM"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_6.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="ihttps://youtu.be/IabS2eZuVto"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_7.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/aDGZgzVwly4"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_8.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="port-img box12">
              <a
                href="https://youtu.be/C-WXTPyI7xQ"
                className="fancybox"
                rel="ligthbox"
              >
                {" "}
                <img
                  loading="lazy"
                  src={require("../assets/images/thumb_9.jpg")}
                />
                <div className="box-content">
                  <ul className="icon">
                    <li>
                      <i className="fa" aria-hidden="true">
                        <FaPlay />
                      </i>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkHome;
