import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import PlansSlider from "../component/sliders/PlansSlider";

const customButtonStyles = {
  color: "#643db4", // Change button color
};
const YourPlan = () => {
  // const navigate = useNavigate();
  const plans = [
    {
      title: "Special Logo",
      price: "$49.00",
      features: [
        "2 Custom Logo Design Concepts",
        "1 Dedicated Designer",
        "3 Revisions",
        "24 - 48 Hours Turn Around Time",
        "FREE Icon Design (JPEG Formats)",
        "100% Unique Design Guarantee",
        "100% Satisfaction Guarantee",
        "100% Money Back Guarantee *",
      ],
    },
    {
      title: "Basic Logo",
      price: "$99.99",
      features: [
        "4 Custom Logo Design Concepts",
        "By 2 Designers",
        "Unlimited Revisions",
        "24 to 48 Hours Turn Around Time",
        "File Formats: Ai, Jpeg",
        "100% Satisfaction Guarantee",
        "100% Unique Design Guarantee",
        "100% Money Back Guarantee*",
      ],
    },
    {
      title: "Professional Logo",
      price: "$169.99",
      features: [
        "8 Logo Design Concepts",
        "By 4 Industry Based Designers",
        "UNLIMITED Revisions",
        "Stationary Design (Business Card, Letterhead, Envelope Design )",
        "24 to 48 Hours Turn Around Time",
        "File Formats (EPS, Ai, GIF, JPEG, PSD)",
        "Dedicated Account Manager",
        "100% Satisfaction Guarantee",
        "100% Unique Design Guarantee",
        "100% Money Back Guarantee",
      ],
    },
    {
      title: "Corporate Logo",
      price: "$249.99",
      features: [
        "Unlimited Logo Design Concepts",
        "By 6 Designers",
        "Unlimited Revisions",
        "FREE Icon Design",
        "24 - 48 Hours TurnAround Time",
        "FREE 1 Stationery Design Set (Business Cards, Letterhead, Envelope & Email Signature)",
        "File Formats: Ai, Eps, Png, Psd, Jpeg, Tiff",
        "100% Satisfaction With Unlimited Revisions",
        "100% Money Back Guarantee*",
      ],
    },
    {
      title: "Elite Logo",
      price: "$399.99",
      features: [
        "3 Logo Design Concepts",
        "By 2 Award Winning Logo Designers",
        "3 Revisions",
        "File Formats: Ai, Eps, Png, Psd, Jpeg, Tiff",
        "3 Page Static Website",
        "1 Year Domain Name",
        "1 Year Web Hosting",
        "Search Engine Friendly HTML",
        "Unlimited Revisions on Complete Package",
        "48 to 72 Hours TurnAround Time",
        "Dedicated Account Manager",
        "100% Satisfaction Guarantee",
        "100% Unique Design Guarantee",
        "100% Money Back Guarantee",
      ],
    },
    {
      title: "Mascot Logo",
      price: "$499.99",
      features: [
        "(1) Mascot Logo",
        "Hand Drawn Sketch",
        "UNLIMITED Revisions",
        "2 Stationary Design Sets",
        "Presentation On Mockup",
        "Stationery Design (Business Card, Letterhead, Envelope, Email Signature)",
        "(2) Days' Time Delivery (Initial 72 hours)",
        "Revision Time Delivery (24 hours)",
        "All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)",
        "100% Satisfaction Guarantee",
        "100% Unique Design Guarantee",
        "100% Money Back Guarantee *",
      ],
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768, // Small screen
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pricing-sec py-[40px] sm:py-[50px] lg:py-[80px]">
      <div className="flex flex-col gap-[30px] lg:gap-[40px]">
        <div className="flex flex-col gap-[10px]">
          <h4 className="meta-card-heading">Start Now</h4>
          <h2 className="meta-subheading ">Your Free Plan Suggestion</h2>
        </div>
        <div className="price-slider">
          <PlansSlider data={plans} />
        </div>
      </div>
    </div>
  );
};

export default YourPlan;
