import { useState } from "react";
import ScreenContainer from "./ScreenContainer";

export default function Accordion({ data }) {
  const [curOpen, setCurOpen] = useState(null);

  return (
    <div className="w-full mb-[40px] sm:mb-[50px] lg:mb-[80px] ">
      <ScreenContainer>

      <h2 className="meta-subheading">FAQ</h2>
      {data.map((el, i) => (
        <AccordionItem
        curOpen={curOpen}
        onOpen={setCurOpen}
        title={el.title}
        num={i}
        key={el.title}
        >
          {el.text}
        </AccordionItem>
      ))}
      </ScreenContainer>
    </div>
  );
}

function AccordionItem({ num, title, curOpen, onOpen, children }) {
  const isOpen = num === curOpen;

  function handleToggle() {
    onOpen(isOpen ? null : num);
  }

  return (
    <div className={` overflow-hidden mb-2  `}>
      <hr className="mt-4" />
      <div
        className="flex items-center pt-8 cursor-pointer "
        onClick={handleToggle}
      >
        <p className="meta-large-para text-textBlack flex-grow">{title}</p>
        <p className="text-xl font-bold">{isOpen ? "-" : "+"}</p>
        <hr className="border-black" />
      </div>

      {isOpen && (
        <div className="py-4 hyphens-auto  text-2xl font-base meta-large-para">{children}</div>
      )}
    </div>
  );
}
