import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import btnArrow from "../../assets/images/btn-arrow-1.png";
import { FaCircleArrowRight, FaCircleArrowLeft } from "react-icons/fa6";
const PlansSlider = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full relative">
      <div className="simple-slider-nav-btns w-[102%] absolute left-[-12px] flex flex-row justify-between z-10 top-[40%]">
        <button className="simple-prev-btn w-[28px] h-[28px]">
          <FaCircleArrowLeft className="w-full h-full text-[#643db4]" />
        </button>
        <button className="simple-next-btn w-[28px] h-[28px] ">
          <FaCircleArrowRight className="w-full h-full text-[#643db4]" />
        </button>
      </div>
      <div className="simple-swiper-container">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          breakpoints={{
            768: { slidesPerView: 1 },
            1024: { slidesPerView: 3 },
          }}
          navigation={{
            nextEl: ".simple-next-btn",
            prevEl: ".simple-prev-btn",
          }}
          centeredSlides={false}
          loop={true}
        >
          {data?.map((plan: any, index: number) => (
            <SwiperSlide key={index}>
              <div className="price-main shadow-sm border-[1px] border-solid border-[rgba(0, 0, 0, 0.1)]">
                <div className="price-main-box">
                  <div className="meta-card-heading">
                    <h3>{plan.title}</h3>
                  </div>
                  <div className="price-rupee-main">
                    <h3>{plan.price}</h3>
                  </div>
                </div>
                <div className="plans-details-card-body flex flex-col gap-[30px]">
                  <div className="pric-list">
                    <ul>
                      {plan.features.map((feature: string, i: number) => (
                        <li key={i} className="meta-paragraph">{feature}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="price-btn flex flex-col gap-3">
                    <a
                      onClick={() => navigate("/contact-us")}
                      className="pri-btn popup-btn flex flex-row justify-center items-center gap-[10px] cursor-pointer"
                    >
                      Get Started
                      <FaCircleArrowRight className="w-[24px] h-[24px]" />
                    </a>
                    <a
                      onClick={() => navigate("/contact-us")}
                      className="pri-btn popup-btn flex flex-row justify-center items-center gap-[10px] cursor-pointer"
                    >
                      View Details
                      <FaCircleArrowRight className="w-[24px] h-[24px]" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PlansSlider;
