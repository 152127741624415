import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import bg from "../assets/images/svg/settings.svg";
import bg1 from "../assets/images/svg/shaking-hands.svg";
import bg2 from "../assets/images/svg/investment.svg";
import bg3 from "../assets/images/svg/fast-delivery.svg";
import one from "../assets/images/svg/increase.svg";
import two from "../assets/images/svg/earn.svg";
import three from "../assets/images/svg/web.svg";
import four from "../assets/images/svg/rating-stars.svg";
import arrow from "../assets/images/arrow_1.svg";
import mobileBg from "../assets/images/sec4_right_bg.webp";
import { FaArrowRightLong } from "react-icons/fa6";
import ReactPlayer from "react-player";
// import { Pagination ,EffectCoverflow, Autoplay } from "swiper";
import {
  Plan,
  creativeTitleParaA,
  logosData,
  testimonial,
  testimonialsHomeTitleParaA,
  testimonialsHomeTitleParaB,
  servicesTitleParaA,
  aewsomeWorkParaA,
} from "../strings/Array";
import { menu } from "../strings/Array";
import creative from "../assets/images/svg/search-engine.svg";
import creative1 from "../assets/images/svg/checking.svg";
import creative2 from "../assets/images/svg/box.svg";
import arrowup from "../assets/images/arrow-up.png";
import arrowdown from "../assets/images/arrow-down.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Design from "../file/Design";
import YourPlan from "../file/YourPlan";
import Project from "../file/Project";
import { FaRegComments } from "react-icons/fa6";
import Chat from "../file/Chat";
import WorkHome from "../file/WorkHome";
import TestimonialCard from "./TestimonialCard";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { CONTACT } from "../strings/CONTACT";
import ScreenContainer from "./ScreenContainer";
import TestimonialSlider from "./sliders/TestimonialSlider";
import TitleWithSubTitleDesc from "./TitleWithSubTitleDesc";
import MobileAppsSlider from "./sliders/MobileAppsSlider";
const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();

  const [user, setUser] = useState({ buisinessname: "" });
  const [activeServices, setActiveServices] = useState(menu[0].service);

  function handleChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;

    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  }

  const images = [
    require("../assets/images/logo1.png"),
    require("../assets/images/logo2.png"),
    require("../assets/images/logo3.png"),
    require("../assets/images/logo4.png"),
    require("../assets/images/logo5.png"),
    require("../assets/images/logo6.png"),
  ];

  const imagesPerSlide = 6; // Number of images to display in each slide
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Advance to the next set of images
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % (images.length - imagesPerSlide + 1)
      );
    }, 5000); // Adjust the interval duration as needed (5 seconds in this example)

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [currentIndex, images.length, imagesPerSlide]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 2,
    // autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
  };

  return (
    <div className="w-full">
      <Header />

      <div className="home-banner home-video-banner ">
        <div className="banner-line">
          <div className="container position-rel-container">
            <div className="exta-div"></div>
          </div>
        </div>
        <div className="animate-box banner-bottom-left-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-left-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-left-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-left-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="animate-box banner-bottom-left-5 onload-animate fadeIn animation-delay-25ms"></div>
        <div className="animate-box banner-bottom-right-1 onload-animate fadeIn animation-delay-5ms"></div>
        <div className="animate-box banner-bottom-right-2 onload-animate fadeIn animation-delay-10ms"></div>
        <div className="animate-box banner-bottom-right-3 onload-animate fadeIn animation-delay-15ms"></div>
        <div className="animate-box banner-bottom-right-4 onload-animate fadeIn animation-delay-20ms"></div>
        <div className="home-banner-video">
          <ReactPlayer
            url={require("../assets/videos/bg.mp4")}
            playing
            loop
            muted
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
            className="w-full h-screen"
          />
        </div>
        <div className="black-overlay"></div>
        <div className="container absolute-center">
          <div className="row">
            <div className="col-12">
              <div className="text-white text-center w-auto flex flex-col justify-center items-center">
                <h1 className="meta-heading w-auto">
                  Welcome To Metanado <br />
                  Your Digital Oasis Of Creativity!
                </h1>
                <p className="my-3 meta-paragraph xl:mx-56 md:mx-32 mx-6">
                  Where Every Brand is Crafted with Distinction. Discover the
                  Unique Branding Your Business Deserves for Digital Eminence.
                </p>
              </div>
              <div className="md:flex gap-3 xl:w-[60%] w-auto mx-auto justify-center items-center xl:px-10 lg:px-32 md:px-32 px-6">
                <div className="home-banner-form">
                  <form
                    action="javascript:;"
                    className="lg:flex justify-center items-center"
                  >
                    <input
                      type="text"
                      name="cname"
                      className="home-banner-input lg:mb-0 mb-3"
                      placeholder="ENTER YOUR BUSINESS NAME HERE"
                      required
                      onChange={handleChange}
                    />{" "}
                    <button
                      type="submit"
                      className="home-banner-submit live-cht lg:w-[50%] w-[100%] flex justify-center items-center md:mb-2"
                    >
                      {" "}
                      <span>LET'S START</span>{" "}
                    </button>{" "}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container absolute-bottom-link">
          <div className="banner-goto-link">
            {" "}
            <a id="banner-scroll-down" rel="nofollow">
              <p className="flex justify-center items-center">
                <img src={require("../assets/images/emoji.png")} />
                Our Awesome Work Is Below
              </p>
              <div className="banner-scroll-down">
                {" "}
                <span className="arrow-down"></span>{" "}
              </div>
            </a>{" "}
          </div>
        </div>

        {/* <div className="container absolute-bottom">
          <div className="row">
              <div className=""> */}
        {/* <div className="image-carousel-container mx-auto large-screen-img">
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className="carousel-image w-[10%]"
              />
            ))}
          </Slider>
         
        </div> */}
      </div>

      <div className="bg-[#F2F2F2] py-[40px] xs:py-[40px] sm:py-[50px] lg:py-[80px]">
        <ScreenContainer>
          <div className=" ">
            <div className="xl:flex gap-6 w-[100%]">
              <div className="flex flex-col justify-start md:items-start items-center md:text-start text-center pt-10 xl:w-[25%] gap-[8px]">
                <div className="flex w-auto md:justify-start justify-center items-center">
                  <img src={arrow} alt="Arrow" className="w-[15%]" />
                  {/* <div className="overla"></div> */}
                  <h1 className="mx-1">How we do</h1>
                  <img
                    src={arrow}
                    alt="Arrow"
                    className="w-[15%] transform rotate-180"
                  />
                </div>
                <h2 className=" meta-subheading">
                  Digitally Focused Quality Design Services
                </h2>
                <p className="meta-paragraph ">
                  Metanado helps you create an everlasting impact on digital, we
                  make sure your presence is felt at every digital platform.
                </p>
                <div className="link-area mt-6">
                  <a
                    className="hover-effect cursor-pointer"
                    onClick={() => navigate("about-us")}
                  >
                    ABOUT US<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-6 pt-10 xl:w-[75%]">
                <div className="flex justify-center items-center h-full">
                  <div className="flex gap-3 h-full">
                    <img src={bg} alt="BG" className="w-[60px] h-[60px]" />
                    <div className="flex flex-col gap-[8px] h-full justify-between">
                      <div>
                        <h1 className="meta-card-heading">Quality Services</h1>
                        <p className="meta-paragraph">
                          We never compromise on the uniqueness of our services.
                          We are known to stand apart from the rest, and our
                          quality is the reason for our supremacy in the digital
                          industry.
                        </p>
                      </div>
                      <button
                        className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-auto"
                        onClick={() => navigate("about-us")}
                      >
                        Discover <FaArrowRightLong />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center h-full">
                  <div className="flex gap-3 h-full">
                    <img src={bg1} alt="BG" className="w-[70px] h-[70px]" />
                    <div className="flex flex-col gap-[8px] h-full justify-between">
                      <div>
                        <h1 className="meta-card-heading">
                          Team Collaboration
                        </h1>
                        <p className="meta-paragraph">
                          Digital branding is not a one-person job; instead, it
                          requires the collaboration of multiple talented
                          creative individuals. Thankfully, we have plenty in
                          our agency.
                        </p>
                      </div>
                      <button
                        className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-auto"
                        onClick={() => navigate("about-us")}
                      >
                        Discover <FaArrowRightLong />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center h-full">
                  <div className="flex gap-3 h-full">
                    <img src={bg2} alt="BG" className="w-[60px] h-[60px]" />
                    <div className="flex flex-col gap-[8px] h-full justify-between">
                      <h1 className="meta-card-heading">ROI Focused</h1>
                      <p className="meta-paragraph">
                        Our creative approach towards digital services
                        guarantees ROI beyond comprehension to boost the digital
                        sale funnel.
                      </p>
                      <button
                        className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-2"
                        onClick={() => navigate("about-us")}
                      >
                        Discover <FaArrowRightLong />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center h-full">
                  <div className="flex gap-3 h-full">
                    <img src={bg3} alt="BG" className="w-[70px] h-[70px]" />
                    <div className="flex flex-col gap-[8px] h-full justify-between">
                      <div>
                        <h1 className="meta-card-heading">Quick Delivery</h1>
                        <p className="meta-paragraph">
                          Our professional environment is enriched with
                          hardworking individuals, enabling us to nail every
                          project in very short times.
                        </p>
                      </div>
                      <button
                        className="flex justify-start items-center font-bold text-[#643db4] gap-2 mt-auto"
                        onClick={() => navigate("about-us")}
                      >
                        Discover <FaArrowRightLong />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div className="flex flex-col gap-[40px] justify-center items-center  w-[100%] overflow-hidden  bg-[#F2F2F2] pb-[40px] sm:pb-[50px] lg:pb-[80px]">
        <TitleWithSubTitleDesc data={aewsomeWorkParaA} />
        <ScreenContainer>
          <WorkHome />
        </ScreenContainer>
      </div>

      <div
        style={{
          backgroundImage: `url(${require("../assets/home/1.jpg")})`,
        }}
        className="w-full bg-no-repeat bg-cover"
      >
        <ScreenContainer>
          <div className="py-[40px]">
            <div>
              <h1 className="meta-subheading !text-white mb-[20px]">
                Offering Affordable Website & Logo Designs for Global Audience
              </h1>
              <p className="meta-paragraph text-white">
                Candigitals has a variety of services to offer to its customers
                at affordable prices. We deliver bespoke design and branding
                solutions at pocket-friendly rates.
              </p>
              <div className="flex justify-start flex-col items-start w-auto mx-auto gap-3 text-white pt-7">
                <div className="link-area">
                  <a
                    className="hover-effect cursor-pointer"
                    onClick={() => navigate("/contact-us")}
                  >
                    GET STARTED<span className="flash-glow-effect"></span>
                  </a>{" "}
                </div>
                <a
                  href={`tel:${CONTACT.PH_NO}`}
                  className="hover:text-logo meta-paragraph hover:text-white"
                >
                  Call Us: {CONTACT.PH_NO}
                </a>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <ScreenContainer>
        <YourPlan />
      </ScreenContainer>
      <ScreenContainer>
        <Design />
      </ScreenContainer>
      {!isMobile && <Project />}

      <div className="pt-[40px] sm:pt-[50px] lg:pt-[80px] ">
        <ScreenContainer>
          <TitleWithSubTitleDesc
            data={servicesTitleParaA}
            bgColor="transparent"
            mainDivClasses="mb-[40px] sm:mb-[60px]"
          />
        </ScreenContainer>
        <ScreenContainer>
          <div className=" w-auto mx-auto flex justify-center items-center ">
            {menu?.map((menu, index) => {
              return (
                <div
                  onClick={() => setActiveServices(menu.service)}
                  className={`flex flex-col justify-center items-center p-[20px] rounded-[8px] text-center cursor-pointer ${
                    activeServices === menu.service
                      ? "bg-[#f4f4f4] text-black font-semibold"
                      : ""
                  }`}
                >
                  {/* bg-[#F1F1F1] */}
                  <img
                    src={menu.image1}
                    alt="Image"
                    className="w-[90px] h-[90px]"
                  />
                  <h1
                    className={`meta-heading-label  ${
                      activeServices === menu.service ? "!font-semibold" : ""
                    }`}
                  >
                    {menu.service}
                  </h1>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col  w-auto mx-auto mt-[40px]">
            {menu.map((menu, id) => {
              return (
                <div
                  key={id}
                  className="fadeIn"
                  style={{
                    display: activeServices === menu.service ? "block" : "none",
                  }}
                >
                  <div className="md:flex  justify-between gap-[20px]">
                    <div className="md:w-[50%] w-auto flex flex-col  ">
                      {/* <div className="flex gap-3">
                      <img src={arrow} alt="Arrow" className="w-[10%]" />
                      <h1 className="meta-heading-label">{menu.title}</h1>
                    </div> */}
                      <h1 className="meta-subheading">{menu.heading}</h1>
                      <h2 className="meta-heading-label !text-secondary">
                        {menu.desc}
                      </h2>
                      <p className="meta-paragraph mt-[10px]">{menu.para}</p>
                      <p className="meta-paragraph mt-[10px]">{menu.para2}</p>
                    </div>
                    <div className="md:w-[50%] w-auto flex justify-center items-center mx-auto mt-[30px]  md:mt-0">
                      <img src={menu.image} alt="Image" width={"100%"} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </ScreenContainer>
      </div>

      <div className="bg-primary w-auto text-white my-[40px] sm:my-[50px] lg:my-[80px]">
        <ScreenContainer>
          <div className="flex lg:flex-row flex-col py-[40px] gap-[30px]">
            <div className="w-full lg:w-[30%] flex flex-col justify-center items-center text-white ">
              <div className="flex lg:justify-start justify-center items-center gap-3 text-center">
                <img src={arrow} alt="Arrow" className="w-[12%] white-image" />
                <h1 className="meta-heading-label !text-white">STATS</h1>
                <img
                  src={arrow}
                  alt="Arrow"
                  className="w-[12%] white-image transform rotate-180 "
                />
              </div>
              <h2 className="meta-subheading !text-white">
                Introduce Your Brand To Digital Media!
              </h2>
              <p className="meta-paragraph mt-[20px]">
                Your brand will steal the limelight from top tier names in the
                marketspace with our creative digital services.
              </p>
            </div>

            <div className="w-[98%] mx-auto lg:w-[70%] flex justify-center items-center">
              <div className="grid lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full gap-[30px] pl-[10px] lg:pl-0">
                <div className="single-stat flex flex-col justify-center items-center w-full">
                  {" "}
                  <img
                    src={one}
                    loading="lazy"
                    alt=""
                    className="w-[70px] h-[70px]"
                  />
                  <p className="stat-head">90%</p>
                  <p className="meta-paragraph text-textBlack">
                    Increase In ROI
                  </p>
                </div>
                <div className="single-stat flex flex-col justify-center items-center w-full">
                  {" "}
                  <img
                    src={two}
                    loading="lazy"
                    alt=""
                    className="w-[70px] h-[70px]"
                  />
                  <p className="stat-head">1570+</p>
                  <p className="meta-paragraph text-textBlack">Projects Done</p>
                </div>
                <div className="single-stat flex flex-col justify-center items-center w-full">
                  {" "}
                  <img
                    src={three}
                    loading="lazy"
                    alt=""
                    className="w-[70px] h-[70px]"
                  />
                  <p className="stat-head">200+</p>
                  <p className="meta-paragraph text-textBlack">
                    Daily Website Visits
                  </p>
                </div>
                <div className="single-stat flex flex-col justify-center items-center w-full">
                  {" "}
                  <img
                    src={four}
                    loading="lazy"
                    alt=""
                    className="w-[70px] h-[70px]"
                  />
                  <p className="stat-head">1000+</p>
                  <p className="meta-paragraph text-textBlack">
                    Satisfied Customers
                  </p>
                </div>
              </div>
              {/* <div className="row mlr-7-mob md:flex gap-16">
                <div className="col-md-3 col-6  w-auto">
                  <div className="single-stat flex flex-col justify-center items-center">
                    {" "}
                    <img
                      src={one}
                      loading="lazy"
                      alt=""
                      className="w-[70px] h-[70px]"
                    />
                    <p className="stat-head">90%</p>
                    <p className="meta-paragraph text-textBlack">
                      Increase In ROI
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="single-stat flex flex-col justify-center items-center">
                    {" "}
                    <img
                      src={two}
                      loading="lazy"
                      alt=""
                      className="w-[70px] h-[70px]"
                    />
                    <p className="stat-head">1570+</p>
                    <p className="meta-paragraph text-textBlack">
                      Projects Done
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="single-stat flex flex-col justify-center items-center">
                    {" "}
                    <img
                      src={three}
                      loading="lazy"
                      alt=""
                      className="w-[70px] h-[70px]"
                    />
                    <p className="stat-head">200+</p>
                    <p className="meta-paragraph text-textBlack">
                      Daily Website Visits
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="single-stat flex flex-col justify-center items-center">
                    {" "}
                    <img
                      src={four}
                      loading="lazy"
                      alt=""
                      className="w-[70px] h-[70px]"
                    />
                    <p className="stat-head">1000+</p>
                    <p className="meta-paragraph text-textBlack">
                      Satisfied Customers
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div className="section4">
        <MobileAppsSlider />
        {/* <ScreenContainer>
          <div className="flex lg:flex-row flex-col gap-[60px] sm:gap-[80px] lg:gap-0">
            <div className="lg:w-[90%] w-full px-3">
              <div className="sec4_lef">
                <div className="px-3">
                  <h1 className="md:text-2xl text-xl font-semibold">
                    Mobile Apps For Better Business Growth
                  </h1>
                  <p>
                    Grow and expand your business with our Mobile App Solutions.
                    Our Mobile Application Services are backed by years of
                    experience, creative minds, and a bond of trust with our
                    clientele.
                  </p>
                  <div className="link-area pt-6">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      className="simple-a"
                      rel="nofollow"
                    >
                      <i className="far fa-comments">
                        <FaRegComments size={25} />
                      </i>
                      Want to discuss?
                    </a>{" "}
                    <a
                      className="hover-effect cursor-pointer"
                      onClick={() => navigate("/contact-us")}
                    >
                      Let's Start<span className="flash-glow-effect"></span>
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-[55%] w-full justify-end items-end ">
              <div className="lg:w-[90%] slick-slider-mobile lg:ml-auto">
                <div className="sec4_right">
                  <div className="mobile-img absolute">
                    <img src={mobileBg} className="w-full h-full" />
                  </div>
                  <Slider {...settings}>
                    <div className="item item1">
                      <img
                        loading="lazy"
                        src={require("../assets/images/sec4_right_item1.webp")}
                        alt="Loading Image..."
                        // className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-[1.4rem] mobile-slider-img".
                        className="mobile-slider-img"
                      />
                    </div>
                    <div className="item item2">
                      <img
                        loading="lazy"
                        src={require("../assets/images/sec4_right_item2.webp")}
                        alt="Loading Image..."
                        // className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-[1.4rem] mobile-slider-img".
                        className="mobile-slider-img"
                      />
                    </div>
                    <div className="item item3">
                      <img
                        loading="lazy"
                        src={require("../assets/images/sec4_right_item3.webp")}
                        alt="Loading Image..."
                         // className="md:h-[50%] w-[75%] h-[75%] lg:pb-10 pb-8 md:w-[55%] md:ml-2 lg:ml-3 xl:ml-4 ml-[1.4rem] mobile-slider-img".
                        className="mobile-slider-img"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </ScreenContainer> */}
      </div>
      <div className="bg-[#F1F1F1] mb-10">
        <ScreenContainer>
          <div className="flex flex-col gap-[40px] py-[50px]">
            <TitleWithSubTitleDesc data={creativeTitleParaA} />
            <div className="w-full">
              <div className="work-process-img">
                {" "}
                <img
                  src={require("../assets/images/logo.jpg")}
                  loading="lazy"
                  alt="Creative Logo Design Process"
                />{" "}
                <a
                  href="https://www.youtube.com/watch?v=QXHuRxjcek8"
                  data-fancybox
                >
                  <div className="work-video-popup">
                    {" "}
                    <img
                      src={require("../assets/images/video-play-btn-black.png")}
                    />{" "}
                    <span className="before"></span>{" "}
                    <span className="after"></span>{" "}
                  </div>
                </a>{" "}
              </div>
            </div>
            <div className="flex mt-[20px] md:flex-row flex-col md:gap-0 gap-[20px]">
              <div className="flex flex-col justify-center items-center gap-[10px]">
                <img src={creative} alt="Creative" className="w-[70px]" />
                <h1
                  className="meta-nav-heading !font-bold"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Initial Phase
                </h1>
                <p className="meta-paragraph text-center">
                  We listen to our clients to get the initial idea of the
                  project. Then our creative teams join their head and polish it
                  into a shining diamond.
                </p>
              </div>

              <img
                src={arrowup}
                alt="Arrow-UP"
                className="large-screen-image w-[20%] h-[10%]"
              />

              <div className="flex flex-col justify-center items-center md:my-0 my-3 gap-[10px]">
                <img src={creative1} alt="Creative" className="w-[70px]" />
                <h1 className="meta-nav-heading !font-bold">Execution</h1>
                <p className="meta-paragraph text-center">
                  We are equipped with skilled people and state-of-the-art
                  rendering machines. Coming up with brilliant products is our
                  habit.
                </p>
              </div>

              <img
                src={arrowdown}
                alt="Arrow-down"
                className="large-screen-image w-[20%] h-[10%]"
              />

              <div className="flex flex-col justify-center items-center gap-[10px]">
                <img src={creative2} alt="Creative" className="w-[70px]" />
                <h1 className="meta-nav-heading !font-bold">Delivery</h1>
                <p className="meta-paragraph text-center">
                  On-time delivery is our best service. Our healthy work
                  environment ensures that you get your project handed over to
                  you ASAP.
                </p>
              </div>
            </div>
          </div>
        </ScreenContainer>
      </div>

      <div className="bg-primary w-auto text-white my-10">
        <ScreenContainer>
          <div className="flex flex-col lg:flex-row justify-between items-center py-[50px] gap-[20px]">
            <h1 className="meta-subheading md:text-start text-center !text-white">
              Pocket-Friendly Design. Just A Click Away!
            </h1>
            <div className="flex justify-center items-center md:mt-0 mt-3">
              <button
                className="md:text-xl p-3 border-2 border-white px-6 hover:bg-white hover:text-logo"
                onClick={() => navigate("/pricing")}
              >
                POCKET PLANS
              </button>
            </div>
          </div>
        </ScreenContainer>
      </div>
      <div className="bg-[#F1F1F1] my-10">
        <div className="flex flex-col justify-center items-center py-10 w-[100%]">
          <TitleWithSubTitleDesc data={testimonialsHomeTitleParaA} />
          <ScreenContainer>
            <div className="slier-section-testimonials my-10">
              <TestimonialSlider data={testimonial} />
            </div>
          </ScreenContainer>
          <div
            className="link-area mb-10"
            onClick={() => navigate("/our-portfolio")}
          >
            <a className="hover-effect cursor-pointer">
              VISIT OUR PORTFOLIO<span className="flash-glow-effect"></span>
            </a>{" "}
          </div>
          <TitleWithSubTitleDesc data={testimonialsHomeTitleParaB} />
        </div>
      </div>

      <Chat />

      <Footer />
    </div>
  );
};

export default Home;
